import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['element']
  submit(event) {
    const form = event.target.closest('form')
    form.submit()
  }

  railsSubmit(event) {
    event.preventDefault()

    if (document.querySelector('.spinner-loader'))
      //si hay el item spinner, se muestra
      document.querySelector('.spinner-loader').classList.remove('invisible')

    const form = event.target.closest('form')
    form.addEventListener('ajax:beforeSend', event => {
      const [xhr, options] = event.detail
      window.filters_timestamp = new Date().getTime()
      xhr.setRequestHeader('timestamp', window.filters_timestamp)
      Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(options.url, Turbolinks.uuid())
    })
    Rails.fire(form, 'submit')
  }
}
