import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['element']

  submit(event) {
    const form = event.target.closest('form')
    form.submit()
  }
}
