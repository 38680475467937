document.addEventListener('turbolinks:load', event => {
  const hideCondition = (el, addButton, maxVideos) => {
    const relatedLinks = el.querySelectorAll('.js-related-link-item')

    let relatedLinksList = []
    relatedLinks.forEach(relatedLink => {
      if (getComputedStyle(relatedLink).display !== 'none') relatedLinksList.push(relatedLink)
    })

    if (relatedLinksList.length >= maxVideos) addButton.classList.add('hidden')
  }

  const showCondition = (el, addButton, maxVideos) => {
    const relatedLinks = el.querySelectorAll('.js-related-link-item')
    let relatedLinksList = []
    relatedLinks.forEach(relatedLink => {
      if (getComputedStyle(relatedLink).display !== 'none') relatedLinksList.push(relatedLink)
    })

    if (relatedLinksList.length < maxVideos) addButton.classList.remove('hidden')
  }

  document.querySelectorAll('.js-video-related-links').forEach(el => {
    const relatedLinks = el.querySelectorAll('.js-related-link-item')
    const addButton = el.querySelector('.add_fields')
    const maxVideos = el.dataset.items
    hideCondition(el, addButton, maxVideos)
    el.addEventListener('cocoon:after-insert', event => {
      hideCondition(el, addButton, maxVideos)
    })

    el.addEventListener('cocoon:after-remove', event => {
      showCondition(el, addButton, maxVideos)
    })
  })
})
