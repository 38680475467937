import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['depthSelect', 'parentSelect', 'contentParent']

  changeDepth(event) {
    let message = ''
    if (event.target.dataset.previousValue === '0') {
      message =
        'Al cambiar la jerarquía de una categoría que tiene subcategorías vinculadas, estos también cambiarán de nivel'
    } else {
      message =
        'Al cambiar la jerarquía de una subcategoría que tiene otras subcategorías vinculadas, estos también cambiarán de nivel'
    }
    const confirmChange = window.confirm(message)
    if (!confirmChange) {
      event.target.value = event.target.dataset.previousValue
    }
    this.setDepth(event.target.value)
  }

  async setDepth(depth) {
    this.depthSelectTarget.dataset.previousValue = depth
    this.parentSelectTarget.innerHTML = ''
    this.parentSelectTarget.add(this.newOption('Selecciona', ''))
    if (depth === '0') {
      this.contentParentTarget.classList.add('hidden')
      this.parentSelectTarget.value = ''
    } else {
      this.contentParentTarget.classList.remove('hidden')
      const response = await fetch(`/admin2/categorias/parents?depth=${depth - 1}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      const themeParents = await response.json()
      for (let themeParent of themeParents) {
        const themeId = this.contentParentTarget.dataset.themeId
        if (themeParent.id.toString() !== themeId) {
          this.parentSelectTarget.add(this.newOption(themeParent.name, themeParent.id))
        }
      }
    }
  }

  newOption(text, value) {
    let option = document.createElement('option')
    option.text = text
    option.value = value
    return option
  }
}
