document.addEventListener('turbolinks:load', event => {
  const elements = document.querySelectorAll('.down-section')
  elements.forEach(element => {
    element.addEventListener('click', e => {
      const section = element.parentNode
      if (section.nextElementSibling) {
        section.parentNode.insertBefore(section.nextElementSibling, section)
        afterOrder()
      }
    })
  })

  const upButtons = document.querySelectorAll('.up-section')
  upButtons.forEach(element => {
    element.addEventListener('click', e => {
      const section = element.parentNode
      if (section.previousElementSibling) {
        section.parentNode.insertBefore(section, section.previousElementSibling)
        afterOrder()
      }
    })
  })

  afterOrder()
})

const afterOrder = () => {
  const elements = document.querySelectorAll('.enable')
  elements.forEach((element, index) => {
    element.querySelector('.campaign_partials_order').value = index + 1
    const upButton = element.querySelector('.up-section')
    const downButton = element.querySelector('.down-section')
    if (index == 0) {
      upButton.classList.add('hidden')
    } else {
      upButton.classList.remove('hidden')
    }

    if (index == elements.length - 1) {
      downButton.classList.add('hidden')
      upButton.classList.add('ml-10')
    } else {
      downButton.classList.remove('hidden')
      upButton.classList.remove('ml-10')
    }
  })
}
