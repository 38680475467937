import { RichUtils } from 'draft-js'
import React, { Component, Fragment } from 'react'
import Autocomplete from 'react-autocomplete'
import axios from 'axios'
import LinkIcon from 'images/icons/insert_link.svg'

// const pDebounce = require('p-debounce')
import pDebounce from '../../../lib/p-debounce'

const getPages = text => axios.get(`/pages.json?palabras=${text}`)

export default class LinkBox extends Component {
  state = {
    setLinkInput: false,
    setLinkurl: '',
    setLinkId: '',
    setLinkType: false,
    setLinkPages: []
  }

  onDebounceHandler = async value => {
    const response = await getPages(value)
    this.setState({
      setLinkPages: response.data
    })
  }
  debounced = pDebounce(this.onDebounceHandler, 1000)

  componentDidUpdate(prevProps, prevState) {
    if (prevState.setLinkurl !== this.state.setLinkurl) {
      this.debounced(this.state.setLinkurl)
    }
  }

  onLinkUrlChange = async event => {
    this.setState({
      setLinkurl: event.target.value
    })
  }

  cancel = () => {
    this.setState({
      setLinkInput: false,
      setLinkurl: '',
      show: false
    })
  }

  onKeyDown = event => {
    if (event.which === 13) {
      this.addLink(event)
    }
  }

  openInput = event => {
    event.preventDefault()

    const { editorState } = this.props
    const selection = editorState.getSelection()

    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent()
      const startKey = editorState.getSelection().getStartKey()
      const startOffset = editorState.getSelection().getStartOffset()
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

      let url = ''

      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey)
        url = linkInstance.getData().url
      }

      this.setState(
        {
          setLinkInput: true,
          setLinkurl: url,
          show: true
        },
        () => {
          setTimeout(() => this.refs.url.focus(), 0)
        }
      )
    }
  }

  add = event => {
    event.preventDefault()
    const { editorState, changeEditorState } = this.props
    const { setLinkurl, setLinkType, setLinkId } = this.state

    let setLinkDecorator = {}

    if (setLinkType) {
      setLinkDecorator.url = setLinkId
    } else {
      setLinkDecorator.url = setLinkurl
      setLinkDecorator.target = '_blank'
    }

    const entity = ['LINK', 'MUTABLE', setLinkDecorator]

    changeEditorState(entity, (newState, entityKey) =>
      RichUtils.toggleLink(newState, newState.getSelection(), entityKey)
    )

    this.setState({
      show: false,
      setLinkInput: false,
      setLinkurl: '',
      setLinkId: '',
      setLinkType: false
    })
  }

  dialog = () => {
    const { setLinkurl, setLinkPages } = this.state
    return (
      <div className="block">
        <Autocomplete
          getItemValue={item => item.name_with_parent}
          onChange={this.onLinkUrlChange}
          ref="url"
          items={setLinkPages}
          menuStyle={{
            position: 'absolute',
            left: '0',
            zIndex: '9',
            top: '56px',
            minWidth: '151px',
            borderRadius: '3px',
            background: '#fff',
            padding: '2px 0px',
            overflow: 'auto',
            maxHeight: '250px',
            boxShadow: '0px 4px 30px 0px rgba(220,220,220,1)'
          }}
          renderItem={(item, isHighlighted) => (
            <div className="autocomplete" key={item.id}>
              {item.name_with_parent}
            </div>
          )}
          onKeyDown={this.onKeyDown}
          value={setLinkurl}
          onSelect={(value, item) => {
            this.setState({
              setLinkurl: value,
              setLinkId: item.path,
              setLinkType: true
            })
          }}
        />

        <button type="button" className="a-btn" onClick={this.add}>
          Guardar
        </button>
        <button type="button" className="a-btn" onClick={this.cancel}>
          Cancelar
        </button>
        <a className="a-btn" href={setLinkurl} target="_blank">
          Ir
        </a>
      </div>
    )
  }
  render() {
    const { entityType } = this.props
    const { show } = this.state
    return (
      <Fragment>
        <button type="button" className={`a-btn ${entityType === 'LINK' ? 'active' : ''}`} onMouseDown={this.openInput}>
          <span className="a-icon-color">
            <img src={LinkIcon} />
          </span>
        </button>
        {show && this.dialog()}
      </Fragment>
    )
  }
}
