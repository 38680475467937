document.addEventListener('turbolinks:load', event => {
  const elements = document.querySelectorAll('.js-propagation-institutionalizable')
  elements.forEach(element => {
    element.addEventListener('ajax:before', event => {
      const link = event.target
      link.text = link.dataset.loadingText
      link.classList.add('pointer-events-none')
    })
    element.addEventListener('ajax:success', event => {
      const [_data, _status, xhr] = event.detail
      const eventTarget = event.target.closest('.js-item-institutionalizable')
      eventTarget.innerHTML = xhr.responseText
    })
  })
})
