import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'preview', 'deleteButton', 'removeInput']

  connect() {
    if (this.previewTarget.dataset.attached === 'true') {
      this.deleteButtonTarget.classList.remove('hidden')
    } else {
      this.deleteButtonTarget.classList.add('hidden')
    }
  }

  preview() {
    const file = this.inputTarget.files[0]
    if (file) {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader()
        reader.onload = e => {
          this.previewTarget.src = e.target.result
          this.previewTarget.classList.remove('hidden')
          this.deleteButtonTarget.classList.remove('hidden')
          this.removeInputTarget.value = false
        }
        reader.readAsDataURL(file)
      } else {
        alert('Por favor, seleccione un archivo de imagen.')
        this.inputTarget.value = ''
      }
    }
  }

  remove() {
    this.inputTarget.value = ''
    this.previewTarget.src = ''
    this.previewTarget.classList.add('hidden')
    this.deleteButtonTarget.classList.add('hidden')
    this.removeInputTarget.value = true
  }
}
