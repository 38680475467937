import { Controller } from 'stimulus'
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  static targets = ['host']

  connect() {
    const popover = document.querySelector(this.hostTarget.dataset.popover)
    if (popover) {
      createPopper(this.hostTarget, popover, { placement: 'right' })
    }
  }

  destroy() {
    console.log('destroying')
    const popover = document.querySelector(this.hostTarget.dataset.popover)
    if (popover) popover.remove()
  }
}
