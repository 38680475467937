import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['hiddenInput']

  connect() {
    const currentIconId = this.hiddenInputTarget.value

    if (currentIconId) {
      this.selectIconById(currentIconId)
    }
  }

  selectIcon(event) {
    const iconElement = event.currentTarget
    const iconId = iconElement.getAttribute('data-icon-id')

    this.element.querySelectorAll('.icon').forEach(icon => {
      icon.classList.remove('selected')
    })

    iconElement.classList.add('selected')

    this.hiddenInputTarget.value = iconId
  }

  selectIconById(iconId) {
    const iconElement = this.element.querySelector(`.icon[data-icon-id="${iconId}"]`)
    if (iconElement) {
      this.selectIcon({ currentTarget: iconElement })
    }
  }
}
