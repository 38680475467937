const getZone = element => (element.closest('header') ? 'header' : element.closest('footer') ? 'footer' : 'body')

const getTimeStampInPeruTimezone = () =>
  new Date().toLocaleString('es-PE', { timeZone: 'America/Lima', hour12: false }).replace(',', '')

const buildFullUrl = relativeUrl =>
  relativeUrl?.startsWith('http') ? relativeUrl : `${window.location.origin}${relativeUrl}`

const getEventData = (element, eventName) => {
  const href = element.getAttribute('href') || ''

  const eventData = {
    event: eventName,
    label: element.getAttribute('data-ga-label') || '',
    zona: getZone(element),
    tipoElemento: element.getAttribute('data-ga-element-type'),
    url: buildFullUrl(href),
    institucion: element.getAttribute('data-ga-institution') || '',
    time_stamp: getTimeStampInPeruTimezone(),
    page_path: window.location.pathname,
    producto: element.getAttribute('data-ga-product') || '',
    titulo_seccion: element.getAttribute('data-ga-title-section') || '',
    titulo_subseccion: element.getAttribute('data-ga-title-subsection') || ''
  }

  if (eventName === 'búsqueda') {
    eventData.valor_buscado = element.value?.trim() || ''
  }

  return eventData
}

const trackEvent = (event, element, eventName) => {
  const dataSend = getEventData(element, eventName)
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataSend)
}

const debounce = (func, delay) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), delay)
  }
}

document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.track-ga-interaction').forEach(element => {
    element.addEventListener('click', event => trackEvent(event, element, 'interaccion'))
  })

  document.querySelectorAll('.track-ga-search').forEach(searchElement => {
    searchElement.addEventListener(
      'input',
      debounce(event => {
        if (searchElement.value.trim()) {
          trackEvent(event, searchElement, 'busqueda')
        }
      }, 2000)
    )
  })
})
