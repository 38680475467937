import { Controller } from 'stimulus'
import { createPopper } from '@popperjs/core'
import { endsWith } from 'lodash'

export default class extends Controller {
  static targets = ['popover']
  showPopover(e) {
    e && e.preventDefault()

    this.popoverTargets.forEach(popover => {
      popover.classList.add('hidden')
      if (e.target.dataset.id === popover.dataset.key) {
        popover.classList.remove('hidden')
        createPopper(e.target, popover, { placement: 'bottom' })
      }
    })
  }

  hidePopover() {
    this.popoverTargets.forEach(element => {
      element.classList.add('hidden')
    })
  }
}
