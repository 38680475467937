import React, { Component, Fragment } from 'react'

export default class Jump extends Component {
  state = {
    show: false,
    pText: '',
    h1Text: '',
    h2Text: ''
  }

  cancel = () => {
    this.setState({
      show: false,
      pText: '',
      h1Text: '',
      h2Text: ''
    })
  }

  add = event => {
    event.preventDefault()
    const { editorState, changeEditorState } = this.props
    const { pText, h1Text, h2Text } = this.state
    const entity = [
      'JUMP',
      'IMMUTABLE',
      {
        pText,
        h1Text,
        h2Text
      }
    ]
    changeEditorState(entity)
    this.setState({
      show: false,
      pText: '',
      h1Text: '',
      h2Text: ''
    })
  }

  openInputJUMP = event => {
    event.preventDefault()

    const { editorState } = this.props
    const selection = editorState.getSelection()

    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent()
      const startKey = editorState.getSelection().getStartKey()
      const startOffset = editorState.getSelection().getStartOffset()
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)

      let pText = ''
      let h1Text = ''
      let h2Text = ''

      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey)
        pText = linkInstance.getData().pText
        h1Text = linkInstance.getData().h1Text
        h2Text = linkInstance.getData().h2Text
      }

      this.setState(
        {
          show: true,
          pText: pText,
          h1Text: h1Text,
          h2Text: h2Text
        }
        // () => {
        //   setTimeout(() => this.refs.pText.focus(), 0)
        // }
      )
    } else {
      this.setState({
        show: true
      })
    }
  }

  onJUMPPChange = event => {
    this.setState({
      pText: event.target.value,
      h1Text: this.state.h1Text,
      h2Text: this.state.h2Text
    })
  }

  onJUMPH1Change = event => {
    this.setState({
      pText: this.state.pText,
      h1Text: event.target.value,
      h2Text: this.state.h2Text
    })
  }

  onJUMPH2Change = event => {
    this.setState({
      pText: this.state.pText,
      h1Text: this.state.h1Text,
      h2Text: event.target.value
    })
  }

  dialog = () => {
    const { pText, h1Text, h2Text } = this.state

    return (
      <div className="block">
        <div className="form-inputs">
          <div className="row row-input">
            <div className="input-left">
              <span className="a-icon-color icon-inline icon-font_s icon-header" />
            </div>
            <div className="input-right">
              <input
                type="text"
                onChange={this.onJUMPPChange}
                ref="pText"
                value={pText}
                className="uk-form-width-large"
              />
            </div>
          </div>
          <div className="row row-input">
            <div className="input-left">
              <span className="a-icon-color icon-inline icon-font_s icon-title" />
            </div>
            <div className="input-right">
              <input
                type="text"
                onChange={this.onJUMPH1Change}
                ref="h1Text"
                value={h1Text}
                className="uk-form-width-large"
              />
            </div>
          </div>
          <div className="row row-input">
            <div className="input-left">
              <span className="a-icon-color icon-inline icon-font_s icon-subtitle" />
            </div>
            <div className="input-right">
              <input
                type="text"
                onChange={this.onJUMPH2Change}
                ref="h2Text"
                value={h2Text}
                className="uk-form-width-large"
              />
            </div>
          </div>

          <div className="row row-input">
            <button className="a-btn" onMouseDown={this.add}>
              Guardar
            </button>
            <button className="a-btn" onMouseDown={this.cancel}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { show } = this.state
    const { entityType } = this.props

    return (
      <Fragment>
        <button type="button" className="a-btn" onMouseDown={this.openInputJUMP}>
          <span className={'a-icon-color icon-message_s' + (entityType === 'JUMP' ? ' active' : '')} />
        </button>
        {show && this.dialog()}
      </Fragment>
    )
  }
}
