import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkbox', 'toggleText', 'btnDisabled']
  connect() {
    this.updateLabel()
    this.disabledContolInstitutionMFA()
  }

  updateLabel() {
    const isChecked = this.checkboxTarget.checked
    const activeText = this.checkboxTarget.getAttribute('data-label-active')
    const inactiveText = this.checkboxTarget.getAttribute('data-label-inactive')
    this.toggleTextTarget.textContent = isChecked ? activeText : inactiveText
  }

  disabledContolInstitutionMFA() {
    const activeText = this.checkboxTarget.getAttribute('data-label-active')
    const isDisabled = this.checkboxTarget.getAttribute('data-disabled')

    if (isDisabled === '1') {
      this.checkboxTarget.checked = true
      this.checkboxTarget.disabled = true
      this.toggleTextTarget.textContent = activeText
      this.btnDisabledTarget.disabled = true
    } else this.checkboxTarget.disabled = false
  }
}
