import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['input']

  copy(event) {
    this.inputTarget.focus()
    this.inputTarget.select()
    document.execCommand('copy')
  }
}
