import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['submitButton']

  select_kind() {
    this.submitButtonTarget.disabled = false
  }
}
