import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['addButton']

  connect() {
    this.addButtonTarget.click()
  }

  triggerClick() {
    const addButtonText = document.getElementById('js-themes-addChildren')
    const disabledAddButton = document.getElementById('js-addButtonDisabled')
    const enabledAddButton = document.getElementById('js-addButtonEnabled')

    this.addButtonTarget.click()

    addButtonText.classList.add('text-gray-500')
    addButtonText.classList.remove('text-blue-500')
    disabledAddButton.classList.remove('hidden')
    enabledAddButton.classList.add('hidden')
  }
}
