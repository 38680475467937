import React, { Component } from 'react'

class Image extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    const { height, src, width } = this.props.contentState.getEntity(this.props.entityKey).getData()

    return <img src={src} height={height} width={width} alt="dummy" />
  }
}

export default Image
