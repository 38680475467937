document.addEventListener('turbolinks:load', event => {
  const clickcustomlink = localStorage.getItem('_click_custom_link')
  const messageDisableCms = document.querySelector('.js-message-disable-cms')
  const cardMessageNotification = document.querySelector('.js-card-message-notification')
  const handCms = document.querySelector('#js-hand-custom-link')
  const card_custom_link = document.getElementById('js-link-pte-additional-info')
  if (!card_custom_link) {
    if (messageDisableCms && handCms) {
      messageDisableCms.classList.add('hidden')
      handCms.classList.add('hidden')
    }
  } else {
    if (clickcustomlink) {
      messageDisableCms.classList.add('hidden')
      handCms.classList.add('hidden')
      card_custom_link.classList.remove('select_msg_custom_link')
    }
    card_custom_link.addEventListener('click', e => {
      localStorage.setItem('_click_custom_link', true)
    })
    const content_institution_direct_link = document.querySelector('.js_institutional_content_label a')
    const height_normal =
      window.pageYOffset + content_institution_direct_link.closest('section').getBoundingClientRect().top
    const rightsection = content_institution_direct_link.closest('section').getBoundingClientRect().right
    const leftMsg = rightsection + 30
    const topMsg = height_normal + 16
    document.querySelector('.js-message-disable-cms').style.top = height_normal + 'px'
    document.querySelector('.js-message-disable-cms').style.left = rightsection + 100 + 'px'
    document.querySelector('#js-hand-custom-link').style.top = topMsg + 'px'
    document.querySelector('#js-hand-custom-link').style.left = leftMsg + 'px'
    content_institution_direct_link.addEventListener('click', e => {
      setTimeout(function () {
        const conditional = document
          .querySelector('.js_institutional_content_label .js-accordion-body')
          .classList.contains('hidden')
        if (!conditional) {
          const height_card = window.pageYOffset + card_custom_link.getBoundingClientRect().top
          document.querySelector('.js-message-disable-cms').style.top = height_card + 'px'
          document.querySelector('#js-hand-custom-link').style.top = height_card + 40 + 'px'
        } else {
          document.querySelector('.js-message-disable-cms').style.top = height_normal + 'px'
          document.querySelector('#js-hand-custom-link').style.top = topMsg + 'px'
        }
      }, 300)
    })
    if (messageDisableCms) {
      const elements = document.querySelectorAll('.js-close-message-disable-cms')
      elements.forEach(element => {
        element.addEventListener('click', e => {
          messageDisableCms.classList.add('hidden')
          handCms.classList.add('hidden')
          card_custom_link.classList.remove('select_msg_custom_link')
        })
      })
    }
  }
  if (cardMessageNotification) {
    const elements = document.querySelectorAll('.js-close-card-message-notification')
    elements.forEach(element => {
      element.addEventListener('click', e => {
        cardMessageNotification.classList.add('hidden')
      })
    })
  }
})
