import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['labelNumber']

  connect() {}

  handleTypeChange(event) {
    const labelNumberTitleRule = this.labelNumberTarget
    const optionText = event.target.options[event.target.selectedIndex].text.toLowerCase().trim()
    if (labelNumberTitleRule != undefined) {
      if (optionText == 'acuerdo' || optionText == 'convenio') {
        labelNumberTitleRule.classList.remove('visible')
        labelNumberTitleRule.classList.add('invisible')
      } else {
        labelNumberTitleRule.classList.remove('invisible')
        labelNumberTitleRule.classList.add('visible')
      }
    }
  }
}
