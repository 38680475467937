import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['parent']

  setParent() {
    this.append()
  }

  append() {
    const tree_parent_text = document.getElementById('tree-padre')

    if (theme_name.value.length > 0) {
      tree_parent_text.innerHTML = this.parentTarget.value
      tree_parent_text.parentElement.classList.remove('hidden')
    }
  }

  changeLanguages(event) {
    let language = event.target.value
    let param_language = '?theme[temp_locale]=' + language
    window.location = param_language
  }
}
