import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'count', 'increase']

  connect() {
    this.compute()
  }

  compute() {
    const remainingCharacters = this.inputTarget.maxLength - this.inputTarget.value.length
    this.countTarget.textContent = remainingCharacters
  }

  increase() {
    const remainingCharacters = this.inputTarget.value.length
    this.increaseTarget.textContent = remainingCharacters
  }
}
