import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button']

  connect() {
    this.buttonTargets.forEach(button => {
      button.addEventListener('click', () => {
        this.buttonTargets.forEach(button => {
          button.classList.remove('bg-white')
        })
        button.classList.add('bg-white')
        const targetId = button.getAttribute('data-id')
        const target = document.getElementById(targetId)
        const content = document.querySelector('.catalog_content')
        const top = target.offsetTop - content.offsetTop

        this.scrollToSmooth(content, top)
      })
    })
  }

  scrollToSmooth(container, to) {
    const start = container.scrollTop
    const change = to - start
    const duration = 500
    let currentTime = 0
    const increment = 20

    const easeInOutQuad = (time, start, change, duration) => {
      time /= duration / 2
      if (time < 1) return (change / 2) * time * time + start
      time--
      return (-change / 2) * (time * (time - 2) - 1) + start
    }

    const animateScroll = () => {
      currentTime += increment
      const val = easeInOutQuad(currentTime, start, change, duration)
      container.scrollTop = val
      if (currentTime < duration) {
        setTimeout(animateScroll, increment)
      }
    }

    animateScroll()
  }
}
