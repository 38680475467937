import { Controller } from 'stimulus'

export default class extends Controller {
  confirm(e) {
    e.preventDefault()
    console.log(this.data)
    console.log(this.data.get('persisted'))
    if (this.data.get('persisted')) {
      if (confirm('Por favor guarda tus cambios. ¿Deseas continuar?') == true)
        window.location.href = e.currentTarget.href
    } else {
      alert('Primero debes guardar la información ingresada')
    }
  }
}
