import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['delete', 'edit', 'name']

  editChild() {
    const editInputId = this.editTarget.getAttribute('data-edit-id') + '_name'
    const editInput = document.getElementById(editInputId)
    const previousChild = document.querySelectorAll('.children_fields_container active')

    this.nameTarget.classList.add('text-black')

    if (previousChild.length > 0) {
      this.hidePreviousChild(previousChild[0])
    }

    editInput.closest('.children_fields_container').classList.remove('hidden')
    editInput.closest('.children_fields_container').classList.add('active')
  }

  hidePreviousChild(previousChild) {
    previousChild.classList.remove('active')
    previousChild.classList.add('hidden')
  }

  removeChild() {
    const deleteButtonId = this.deleteTarget.getAttribute('data-destroy-id') + '__destroy'
    const deleteButton = document.getElementById(deleteButtonId)

    this.deleteTarget.parentNode.parentNode.remove()

    deleteButton.parentNode.querySelector('.remove_fields').click()
  }
}
