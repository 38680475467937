import React, { Component, Fragment } from 'react'
import ImageIcon from 'images/icons/insert_photo.svg'

export default class ImageBox extends Component {
  state = {
    show: false,
    url: ''
  }

  onUrlChange = event => {
    this.setState({
      show: true,
      url: event.target.value
    })
  }

  add = event => {
    event.preventDefault()
    const { changeEditorState } = this.props
    const { url } = this.state
    const entity = ['IMAGE', 'IMMUTABLE', { src: url }]
    changeEditorState(entity)
    this.setState({
      show: false,
      url: ''
    })
  }

  cancel = () => {
    this.setState({
      show: false,
      url: ''
    })
  }

  openInput = () => {
    this.setState({
      show: true,
      url: ''
    })
  }

  onKeyDown = event => {
    if (event.which === 13) {
      this.add(event)
    }
  }

  render() {
    const { entityType } = this.props
    const { url, show } = this.state
    return (
      <Fragment>
        <button type="button" className="a-btn" onMouseDown={this.openInput}>
          <span className={'a-icon-color ' + (entityType === 'IMAGE' ? ' active' : '')}>
            <img src={ImageIcon} />
          </span>
        </button>
        {show && (
          <div className="block">
            <input onChange={this.onUrlChange} ref="url" type="text" value={url} onKeyDown={this.onKeyDown} />
            <button className="a-btn" onMouseDown={this.add}>
              Guardar
            </button>
            <button className="a-btn" onMouseDown={this.cancel}>
              Cancelar
            </button>
          </div>
        )}
      </Fragment>
    )
  }
}
