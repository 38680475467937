import Choices from 'choices.js'
// const pDebounce = require('p-debounce')
import qs from 'query-string'
import pDebounce from '../lib/p-debounce'
const generateChoices = object => {
  object.url = object.dataset.url
  const searchQuery = object.dataset.search_query || 'search'
  const choices = object.dataset.choices ? JSON.parse(object.dataset.choices) : []
  const containerInner =
    object.dataset.class_name == 'branch-offices' ? 'branch-offices choices__inner__custom' : 'choices__inner__custom'
  object.choicesDocuments = new Choices(object, {
    shouldSort: !(object.dataset.shouldsort === 'false'),
    removeItemButton: true,
    noChoicesText: 'No hay opciones para elegir',
    noResultsText: 'No se han encontrado resultados',
    itemSelectText: 'Seleccionar',
    maxItemCount: object.dataset.maxItemCount || -1,
    maxItemText: object.dataset.maxItemText || '',
    placeholder: true,
    placeholderValue: 'Seleccionar...',
    searchResultLimit: 50,
    choices,
    classNames: {
      containerInner: containerInner,
      input: 'choices__input__custom',
      placeholder: 'opacity-100',
      listItems: 'choices__list--multiple pointer-events-none',
      button: 'choices__button pointer-events-auto'
    }
  })

  const handleSearch = async event => {
    try {
      object.url = object.dataset.url
      if (!object.url) return
      const params = qs.parse(object.url?.split('?')?.[1])
      const stringParams = qs.stringify({ ...params, search: event.detail.value })
      object.url = `${object.url?.split('?')[0]}?${stringParams}`
      const response = await fetch(object.url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      const items = await response.json()
      const options = items.map(item => {
        return { value: item.id, label: item.label }
      })
      object.choicesDocuments.setChoices(options, 'value', 'label', true)
    } catch (err) {
      console.error(err)
    }
  }

  const debounced = pDebounce(handleSearch, 500)

  if (object.dataset.maxItemCount != null) {
    object.addEventListener(
      'change',
      event => {
        maxCountEvent(object)
      },
      false
    )

    object.addEventListener(
      'showDropdown',
      event => {
        maxCountEvent(object)
      },
      false
    )
  }

  if (object.url != null) {
    object.addEventListener(
      'search',
      event => {
        debounced(event, object.choicesDocuments)
      },
      false
    )
  }

  return object
}

export { generateChoices }

const maxCountEvent = object => {
  if (
    object.choicesDocuments.choiceList.element.querySelector('.has-no-choices') != null &&
    object.choicesDocuments.itemList.element.childElementCount >= object.dataset.maxItemCount
  ) {
    object.choicesDocuments.config.noChoicesText = object.dataset.maxItemText
    object.choicesDocuments.choiceList.element.querySelector('.has-no-choices').textContent = object.dataset.maxItemText
  } else if (object.choicesDocuments.itemList.element.childElementCount >= object.dataset.maxItemCount) {
    object.choicesDocuments.config.noChoicesText = object.dataset.maxItemText
  } else {
    object.choicesDocuments.config.noChoicesText = 'No hay opciones para elegir'
  }
}
