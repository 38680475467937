//import Rails from 'rails-ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['label_fecha', 'label_numero']

  connect() {}

  change() {
    const doc_name = event.target.value
    this.label_fechaTarget.innerText = `Fecha de emisión de ${doc_name}`
    this.label_numeroTarget.innerText = `Número de ${doc_name}`
  }
}
