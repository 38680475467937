import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['slides', 'caption']

  connect() {
    this.currentIndex = 0
    this.images = this.slidesTarget.children
    this.totalSlides = this.images.length
    this.startAutoplay()
    this.showSlide(this.currentIndex)
  }

  showSlide(index) {
    Array.from(this.images).forEach((image, i) => {
      if (i === index) {
        image.classList.add('opacity-100')
        image.classList.remove('opacity-0')
      } else {
        image.classList.add('opacity-0')
        image.classList.remove('opacity-100')
      }
    })

    const caption = this.images[index].getAttribute('data-caption')
    this.captionTarget.textContent = caption
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.totalSlides
    this.showSlide(this.currentIndex)
  }

  startAutoplay() {
    this.interval = setInterval(() => this.next(), 5000)
  }

  disconnect() {
    clearInterval(this.interval)
  }
}
