import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select']

  connect() {
    setTimeout(() => this.showContentType(this.selectTarget), 0)
  }

  changeInstitution(event) {
    this.changeInstitutionHighlightPageUrl(event.target.value)
    this.showContentType(event.target)
  }

  changeInstitutionNotTransversal(event) {
    const institutionId = event.target.value
    Object.values(this.contentTypes()).forEach($el => this.updateUrlSearchParams($el, institutionId))
    this.changeInstitutionHighlightPageUrl(institutionId)
    this.showContentType(event.target)
  }

  changeInstitutionHighlightPageUrl(institution_id) {
    const highlight_page_id = document.getElementById('theme_highlight_page_id')
    if (highlight_page_id) {
      this.updateUrlSearchParams(highlight_page_id, institution_id)
      highlight_page_id.choicesDocuments.clearStore()
    }
  }

  changeContentTypes() {
    this.showContentType(document.getElementById('theme_institution_content_id'))
  }

  changeTransversal(event) {
    if (event.target.value === 'true') {
      document.querySelector('.js-for-transversal').classList.remove('hidden')
    } else {
      document.querySelector('.js-for-transversal').classList.add('hidden')
    }
    document.getElementById('theme_institution_content_id').choicesDocuments.clearStore()
    this.changeContentTypes()
  }

  async showContentType(institutionContentType) {
    const isTransversal = document.querySelector('input[name="theme[transversal]"]:checked')?.value || false
    const institutionId = document.getElementById('theme_institution_id')?.value || false
    const institutionContentId = isTransversal === 'true' ? institutionContentType.value : institutionId
    const contentType = this.contentTypes()
    if (institutionContentId)
      Object.values(contentType).forEach($el => this.updateUrlSearchParams($el, institutionContentId))

    if (institutionContentId === '') {
      Object.values(contentType).forEach($el => this.showAllSelectedOptions($el))
    } else {
      const themeId = document.getElementById('theme_id').value
      const params = {
        id: themeId,
        theme: { institution_content_id: institutionContentId }
      }
      Object.entries(contentType).forEach(([type, $el]) => (params.theme[type] = $el.choicesDocuments.getValue(true)))
      Object.values(contentType).forEach($el => this.hideAllSelectedOptions($el))
      let response = await this.makeRequest('/admin2/categorias/content_by_institution.json', params)
      Object.entries(contentType).forEach(([type, $el]) => {
        this.showSelectedOptions($el, response[type])
      })
    }
  }

  showAllSelectedOptions(element) {
    const $choiceList = element.nextElementSibling
    if ($choiceList) {
      $choiceList.querySelectorAll('.choices__item').forEach(item => item.classList.add('active'))
      this.setCountContentTypes(element)
    }
  }

  hideAllSelectedOptions(element) {
    const $choiceList = element.nextElementSibling
    if ($choiceList) {
      $choiceList.querySelectorAll('.choices__item').forEach(item => item.classList.remove('active'))
      this.setCountContentTypes(element, true)
    }
  }

  showSelectedOptions(element, ids) {
    const $choiceList = element.nextElementSibling
    if ($choiceList) {
      ids.forEach(id => $choiceList.querySelector(`.choices__item[data-value="${id}"]`).classList.add('active'))
      this.setCountContentTypes(element)
    }
  }

  setCountContentTypes(element, isLoading = false) {
    const $choiceList = element.nextElementSibling
    element.closest('.js-selected-options-count').querySelector('.showCount').textContent = isLoading
      ? '...'
      : $choiceList.querySelectorAll('.choices__item.active').length
  }

  updateUrlSearchParams(el, institutionId) {
    const url = el.dataset.url
    const [path, searchParams] = url.split('?')
    const params = new URLSearchParams(searchParams)
    params.set('institution_id', institutionId)
    el.dataset.url = `${path}?${params.toString()}`
    return true
  }

  makeRequest(url, data) {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest()
      xhr.open('POST', url)
      xhr.setRequestHeader('Content-type', 'application/json')
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(JSON.parse(xhr.response))
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText
          })
        }
      }
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText
        })
      }
      xhr.send(JSON.stringify(data))
    })
  }

  contentTypes() {
    return {
      page_ids: document.getElementById('theme_page_ids'),
      rule_ids: document.getElementById('theme_rule_ids'),
      feed_ids: document.getElementById('theme_feed_ids'),
      report_ids: document.getElementById('theme_report_ids'),
      campaign_ids: document.getElementById('theme_campaign_ids'),
      documents_collection_ids: document.getElementById('theme_documents_collection_ids')
    }
  }
}
