document.addEventListener('turbolinks:load', event => {
  const elements = document.querySelectorAll('.down-children-page')
  elements.forEach(element => {
    element.addEventListener('click', e => {
      const section = element.parentNode
      if (section.nextElementSibling) {
        section.parentNode.insertBefore(section.nextElementSibling, section)
        afterOrder(element.parentNode.getAttribute('data-PageId'), 'down')
      }
    })
  })

  const upButtons = document.querySelectorAll('.up-children-page')
  upButtons.forEach(element => {
    element.addEventListener('click', e => {
      const section = element.parentNode
      if (section.previousElementSibling) {
        section.parentNode.insertBefore(section, section.previousElementSibling)
        afterOrder(element.parentNode.getAttribute('data-PageId'), 'up')
      }
    })
  })

  printArrows()
})

const afterOrder = async (pageId, direction) => {
  printArrows()
  const elements = document.querySelectorAll('.children-page')
  const childrenPagesIds = Array.from(elements).map(ch => ch.getAttribute('data-PageId'))

  const index1ToUpdate = childrenPagesIds.indexOf(pageId)
  const index2ToUpdate = direction === 'down' ? index1ToUpdate - 1 : index1ToUpdate + 1

  const pageId1 = childrenPagesIds[index1ToUpdate]
  const pageId2 = childrenPagesIds[index2ToUpdate]

  await updatePosition(pageId1, index1ToUpdate)
  await updatePosition(pageId2, index2ToUpdate)
}

const updatePosition = (pageId, position) => {
  const values = { position }
  return fetch(`/admin2/paginas/${pageId}.json`, {
    method: 'PUT',
    body: JSON.stringify({ page: values }),
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      'Content-Type': 'application/json'
    }
  })
}

const printArrows = () => {
  const elements = document.querySelectorAll('.children-page')
  const parsedEl = Array.from(elements)

  parsedEl.forEach((element, index) => {
    const upButton = element.querySelector('.up-children-page')
    const downButton = element.querySelector('.down-children-page')
    if (index == 0) {
      upButton.classList.add('hidden')
    } else {
      upButton.classList.remove('hidden')
    }

    if (index == elements.length - 1) {
      downButton.classList.add('hidden')
      upButton.classList.add('ml-10')
    } else {
      downButton.classList.remove('hidden')
      upButton.classList.remove('ml-10')
    }
  })
}
