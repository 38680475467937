import React, { Component } from 'react'

class Jumbotron extends Component {
  render() {
    // console.log('this.props', this.props);
    const { pText, h1Text, h2Text } = this.props.contentState.getEntity(this.props.entityKey).getData()

    return (
      <jump>
        <div className="holidays">
          <div style={styles.jumbotron} className="jumbotron">
            <p style={styles.p}>{pText}</p>
            <h1 style={styles.h1}>{h1Text}</h1>
            <h2 style={styles.h2}>{h2Text}</h2>
          </div>
        </div>
      </jump>
    )
  }
}

const styles = {
  jumbotron: {
    backgroundColor: '#2a9949',
    padding: '25px 10px',
    color: 'white',
    textAlign: 'center'
  },
  p: {
    color: 'white',
    marginBottom: 0
  },
  h1: {
    color: 'white',
    fontSize: '3rem',
    marginTop: 0,
    marginBottom: '1rem',
    fontWeight: '400'
  },
  h2: {
    color: 'white',
    fontSize: '1.5rem',
    margin: 0
  }
}
export default Jumbotron
