import { Controller } from 'stimulus'
import qs from 'query-string'
import Choices from 'choices.js'
// const pDebounce = require('p-debounce')
import pDebounce from '../../lib/p-debounce'

export default class extends Controller {
  static targets = ['input']
  searchInfo = {
    rule: 'normas-legales',
    report: 'informes-publicaciones',
    feed: 'noticias',
    campaign: 'campañas',
    documents_collection: 'compendios',
    home: 'home'
  }
  url = null

  connect() {
    const el = this.inputTarget
    const kind = el.dataset.kind
    const params = qs.parse(this.url?.split('?')?.[1])
    const institution_id = el.dataset.institutionId

    const stringParams = qs.stringify({ ...params, institution_id, type: el.dataset.type })
    if (this.searchInfo[kind] !== undefined) this.url = `/admin2/${this.searchInfo[kind]}/search.json?${stringParams}`

    this.maxqty = Number(el.dataset.maxqty)
    const choices = el.dataset.choices ? JSON.parse(el.dataset.choices) : []
    this.choicesDocument = new Choices(el, {
      removeItemButton: true,
      noChoicesText: 'No hay opciones para elegir',
      noResultsText: 'No se han encontrado resultados',
      maxItemText: maxItemCount => {
        return `Solo ${maxItemCount} resultados pueden ser elegidos`
      },
      itemSelectText: 'Seleccionar',
      placeholder: true,
      placeholderValue: 'Búsqueda de resolución',
      searchResultLimit: 50,
      maxItemCount: this.maxqty,
      shouldSort: false,
      choices,
      classNames: { containerInner: 'choices__inner' }
    })

    if (this.maxqty != null) {
      el.addEventListener(
        'change',
        event => {
          this.maxCountEvent()
        },
        false
      )

      el.addEventListener(
        'showDropdown',
        event => {
          this.maxCountEvent()
        },
        false
      )
    }

    const handleSearch = async event => {
      try {
        if (!this.url) return
        const params = qs.parse(this.url?.split('?')?.[1])
        const stringParams = qs.stringify({ ...params, search: event.detail.value })
        this.url = `${this.url?.split('?')[0]}?${stringParams}`
        const response = await fetch(this.url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        const items = await response.json()
        const options = items.map(item => {
          return { value: item.id, label: item.label }
        })
        this.choicesDocument.setChoices(options, 'value', 'label', true)
      } catch (err) {
        console.error(err)
      }
    }

    const debouncedSearch = pDebounce(handleSearch, 500)
    this.inputTarget.addEventListener('search', debouncedSearch, false)
  }

  maxCountEvent() {
    if (
      this.choicesDocument.choiceList.element.querySelector('.has-no-choices') != null &&
      this.choicesDocument.itemList.element.childElementCount >= this.maxqty
    ) {
      this.choicesDocument.config.noChoicesText = this.maxItemText()
      this.choicesDocument.choiceList.element.querySelector('.has-no-choices').textContent = this.maxItemText()
    } else if (this.choicesDocument.itemList.element.childElementCount >= this.maxqty) {
      this.choicesDocument.config.noChoicesText = this.maxItemText()
    } else {
      this.choicesDocument.config.noChoicesText = 'No hay opciones para elegir'
    }
  }

  maxItemText() {
    return `Solo ${this.maxqty} resultados pueden ser elegidos`
  }
}
