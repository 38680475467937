import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select', 'link']

  connect() {
    this.updateLink()
  }

  updateLink() {
    const locale = this.selectTarget.value
    const link = this.linkTarget
    const baseUrl = link.dataset.baseurl

    if (locale !== '') {
      link.href = `${baseUrl}${locale}`
    } else {
      link.href = ''
    }
  }

  validateSelection(event) {
    const locale = this.selectTarget.value
    this.removeErrorMessage()

    if (locale === '') {
      this.showErrorMessage('*Campo requerido')
      event.preventDefault()
    }
  }

  showErrorMessage(message) {
    const errorMessage = document.createElement('div')
    errorMessage.classList.add('text-red-500', 'text-xs', 'my-1')
    errorMessage.innerText = message

    this.selectTarget.parentNode.parentNode.appendChild(errorMessage)
  }

  removeErrorMessage() {
    const existingError = this.selectTarget.parentNode.parentNode.querySelector('.text-red-500')
    if (existingError) {
      existingError.remove()
    }
  }
}
