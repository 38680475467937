import { Controller } from 'stimulus'

export class SortableController extends Controller {
  connect() {
    this.updateOrder()
  }

  upDownSection(event) {
    const { container, upButton, downButton } = this.selectors

    const containerClassName = container.replace(/^\./, '')
    const upButtonClassName = upButton.replace(/^\./, '')
    const downButtonClassName = downButton.replace(/^\./, '')

    const section = this.findAncestor(event.target, containerClassName)

    if (event.currentTarget.classList.contains(upButtonClassName)) {
      const previousElementSibling = this.getSibling(section, container, 'previous')
      if (previousElementSibling) {
        section.parentNode.insertBefore(section, previousElementSibling)
      }
    }
    if (event.currentTarget.classList.contains(downButtonClassName)) {
      const nextElementSibling = this.getSibling(section, container)
      if (nextElementSibling) {
        section.parentNode.insertBefore(nextElementSibling, section)
      }
    }
    this.updateOrder()
  }

  updateOrder() {
    const { container, positionInput, upButton, downButton } = this.selectors

    const elements = Array.from(document.querySelectorAll(container)).filter(child => child.style.display == !'none')
    if (!elements) return

    elements.forEach((element, index) => {
      const positionInputEl = element.querySelector(positionInput)
      const upButtonEl = element.querySelector(upButton)
      const downButtonEl = element.querySelector(downButton)

      if (positionInputEl) {
        positionInputEl.value = index + 1
      }
      if (upButtonEl) {
        upButtonEl.classList.remove('hidden')
      }
      if (downButtonEl) {
        downButtonEl.classList.remove('hidden')
      }
    })
    elements[0]?.querySelector(upButton)?.classList.add('hidden')
    elements[elements.length - 1]?.querySelector(downButton)?.classList.add('hidden')
  }

  getSibling(elem, selector, typeSibling = 'next') {
    let sibling = typeSibling === 'next' ? elem.nextElementSibling : elem.previousElementSibling
    if (!selector) return sibling

    while (sibling) {
      if (sibling.matches(selector)) return sibling
      sibling = typeSibling === 'next' ? sibling.nextElementSibling : sibling.previousElementSibling
    }
  }

  findAncestor(elem, selector) {
    while ((elem = elem.parentNode) && !elem.classList.contains(selector));
    return elem
  }
}
