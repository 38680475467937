document.addEventListener('turbolinks:load', event => {
  const elements = document.querySelectorAll('.js-documents-fields-file')
  elements.forEach(element => {
    writeTitle(element)
  })
})

document.addEventListener('turbolinks:load', event => {
  const elements = document.querySelectorAll('.js-documents')
  elements.forEach(element => {
    element.addEventListener('cocoon:after-remove', event => {
      const target = event.target
      const children = Array.from(target.querySelectorAll('.js-documents-fields')).filter(
        child => child.style.display != 'none'
      )
      if (children.length === 1) {
        children[0].querySelector('.js-documents-fields-remove').classList.add('hidden')
      } else {
        children.forEach(child => child.querySelector('.js-documents-fields-remove').classList.remove('hidden'))
      }
      disableAddButton(elements)
    })
    element.addEventListener('cocoon:after-insert', event => {
      const target = event.target.closest('.js-documents')
      const elementsTarget = target.querySelectorAll('.js-documents-fields-file')
      elementsTarget.forEach(element => {
        writeTitle(element)
      })
      Array.from(target.querySelectorAll('.js-documents-fields')).forEach(child =>
        child.querySelector('.js-documents-fields-remove').classList.remove('hidden')
      )
      disableAddButton(elements)
    })
    disableAddButton(elements)
  })
})

const writeTitle = element => {
  element.addEventListener('change', event => {
    const file = event.target
    let title = file.value.split('\\').pop() || ''
    title = title.replace(/\.[^\/.]+$/, '')
    file.closest('.js-documents-fields').querySelector('.js-documents-fields-title').value = title
  })
}

const disableAddButton = elements => {
  elements.forEach(el => {
    const maxItems = el.dataset.maxItems
    const countFields = Array.from(el.querySelectorAll('.js-documents-fields')).filter(
      child => child.style.display == !'none'
    )
    const style =
      countFields.length >= maxItems
        ? 'pointer-events: none; color: #919191; border: 1px solid gray'
        : 'color: #0056ac; border: 1px solid #0056ac'
    el.querySelectorAll('.js-inputs-fields-add').forEach(btnAdd => (btnAdd.style = style))
  })
}
