import React from 'react'
import LinkBox from './LinkBox'
import ImageBox from './ImageBox'
import BlockStyleControls from '../controls/block_style_controls'
import InlineStyleControls from '../controls/inline_style_controls'
import Jump from './Jump'
import MAC from './MAC'
import CodeGuide from './CodeGuide'
import RemoveStyleBox from './RemoveStyleBox'

const Toolbar = ({
  changeEditorState,
  editorState,
  isSimpleBox,
  isRequirementBox,
  onlyLinkBox,
  showJump,
  toggleInlineStyle,
  toggleBlockType,
  changeEditorStateRaw,
  blackListControls = []
}) => {
  let entityType = ''
  const contentState = editorState.getCurrentContent()
  const block = contentState.getBlockForKey(editorState.getSelection().getStartKey())
  const objKey = block.getEntityAt(editorState.getSelection().getStartOffset())
  if (objKey) entityType = contentState.getEntity(objKey).getType()

  if (onlyLinkBox) {
    return (
      <div className="controls-default">
        <LinkBox entityType={entityType} editorState={editorState} changeEditorState={changeEditorState} />
      </div>
    )
  }

  return (
    <div className="controls-default w-full">
      {!blackListControls.includes('ImageBox') && (
        <ImageBox entityType={entityType} editorState={editorState} changeEditorState={changeEditorState} />
      )}
      {!blackListControls.includes('LinkBox') && (
        <LinkBox entityType={entityType} editorState={editorState} changeEditorState={changeEditorState} />
      )}

      {/* <ButtonBox
          editorState={editorState}
          changeEditorState={changeEditorState}
        /> */}

      {!blackListControls.includes('BlockStyleControls') && (
        <BlockStyleControls
          isSimpleBox={blackListControls.includes('PasoAPaso') || isSimpleBox}
          isRequirementBox={isRequirementBox}
          blackListControls={blackListControls}
          editorState={editorState}
          onToggle={toggleBlockType}
        />
      )}

      {!blackListControls.includes('InlineStyleControls') && (
        <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
      )}

      {!blackListControls.includes('RemoveStyleBox') && (
        <RemoveStyleBox editorState={editorState} changeEditorState={changeEditorStateRaw} />
      )}

      {!blackListControls.includes('Jump') && showJump && (
        <Jump entityType={entityType} editorState={editorState} changeEditorState={changeEditorState} />
      )}

      {!blackListControls.includes('MAC') && !isSimpleBox && (
        <MAC editorState={editorState} changeEditorState={changeEditorState} />
      )}

      {!blackListControls.includes('CodeGuide') && !isSimpleBox && (
        <CodeGuide editorState={editorState} changeEditorState={changeEditorState} />
      )}
    </div>
  )
}

export default Toolbar
