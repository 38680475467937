import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element']

  toggle(event) {
    this.elementTargets.forEach((el, i) => {
      el.classList.toggle('hidden')
    })
  }

  toggleDisable(event) {}

  toggleModal(event) {
    const modalId = event.target.dataset.modalId
    const modal = document.getElementById(modalId)
    const modalBackdrop = document.getElementById(modalId + '-backdrop')
    if (modal && modalBackdrop) {
      modal.classList.toggle('hidden')
      modalBackdrop.classList.toggle('hidden')
    }
  }
}
