import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['mask_qr', 'container_qr', 'mfa_code', 'mfa_code_confirmation', 'btn_submit']
  connect() {
    this.btn_submitTarget.setAttribute('disabled', 'disabled')
  }
  show_qr() {
    this.mask_qrTarget.classList.add('hidden')
    this.container_qrTarget.classList.remove('hidden')
  }
  change_any_mfa_code(target) {
    if (target.value.length == 6) {
      this.btn_submitTarget.removeAttribute('disabled')
    } else {
      this.btn_submitTarget.setAttribute('disabled', 'disabled')
    }
  }
  change_mfa_code() {
    this.change_any_mfa_code(this.mfa_codeTarget)
  }
  change_mfa_code_confirmation() {
    this.change_any_mfa_code(this.mfa_code_confirmationTarget)
  }
}
