import { Spanish } from 'flatpickr/dist/l10n/es.js'

document.addEventListener('turbolinks:load', () => {
  const datePicker = document.querySelectorAll('.js-date-picker')
  if (datePicker.length > 0) {
    import('flatpickr').then(flatpickr => {
      const defaultFlatpickr = flatpickr.default
      require('flatpickr/dist/themes/light.css')
      Array.from(datePicker).forEach(tabs => {
        const attributes = tabs.dataset.attributes === undefined ? {} : JSON.parse(tabs.dataset.attributes)
        const fp = new defaultFlatpickr('.js-date-picker', {
          dateFormat: 'd/m/Y',
          locale: Spanish,
          ...attributes
        })
      })
    })
  }
})
