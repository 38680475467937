import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['showCount', 'select']

  connect() {
    this.selectTargets.forEach(selectTarget => this.showCount(selectTarget))
  }

  change(event) {
    this.showCount(event.target)
  }

  showCount(selectTarget) {
    this.showCountTargets.forEach(showCountTarget => {
      showCountTarget.textContent = selectTarget.querySelectorAll('option:checked').length
    })
  }
}
