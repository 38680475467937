import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['allowed', 'types', 'institutions']

  changeTransversal(e) {
    const isTransversal = e.target.value == 'true'
    this.allowedTarget.hidden = !isTransversal
    document.getElementById('digital-section').classList.toggle('hidden')
  }

  handleAllowed(e) {
    const selected = e.target.value
    this.institutionsTarget.hidden = selected != 'institutions'
    this.typesTarget.hidden = selected != 'institution_types'
  }

  openHiddenSectionTransversal(e) {
    const sectionPageId = e.target.getAttribute('page-id')
    document.getElementById('see-more-' + sectionPageId).classList.toggle('hidden')
    document.getElementById('see-less-' + sectionPageId).classList.toggle('hidden')
    document.getElementById('see-actions-' + sectionPageId).classList.toggle('hidden')
    document.getElementById('list-transversal-' + sectionPageId).classList.toggle('hidden')
  }
}
