import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['redirectHelperText']
  async updateHelperText(e) {
    const institutionId = e.target.value
    if (!institutionId) {
      return (this.redirectHelperTextTarget.innerHTML = '')
    }
    const url = `/admin2/instituciones/${institutionId}/servicio-verificador/redirect_helper_text.json`
    const response = await fetch(url)
    const json = await response.json()
    this.redirectHelperTextTarget.textContent = json.text
  }
}
