import { Controller } from 'stimulus'
export default class extends Controller {
  dragstart(event) {
    event.dataTransfer.setData('application/drag-key', event.target.querySelector('input').getAttribute('value'))
    event.dataTransfer.effectAllowed = 'move'
  }

  dragover(event) {
    event.preventDefault()
    return true
  }

  dragenter(event) {
    event.preventDefault()
  }

  drop(event) {
    const data = event.dataTransfer.getData('application/drag-key')
    const dropTarget = event.target.parentElement
    const draggedItem = this.element.querySelector(`input[value='${data}']`).parentElement
    const positionComparison = dropTarget.compareDocumentPosition(draggedItem)
    if (positionComparison & 4) {
      event.target.parentElement.insertAdjacentElement('beforebegin', draggedItem)
    } else if (positionComparison & 2) {
      event.target.parentElement.insertAdjacentElement('afterend', draggedItem)
    }

    Array.from(document.querySelectorAll('.js-partial-position'))
      .filter(partial => partial.parentElement.style.display != 'none')
      .forEach((item, index) => item.setAttribute('value', index + 1))

    event.preventDefault()
  }

  dragend(event) {}
}
