import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['num_doc', 'name', 'person_id', 'create_person', 'message', 'type_doc']

  connect() {
    if (this.person_idTarget.value && this.nameTarget.value) {
      this.nameTarget.disabled = true
    }
  }

  search(event) {
    const elementTypeDoc = this.type_docTarget
    const elementNumDoc = this.num_docTarget
    const numDoc = elementNumDoc.value
    const elementName = this.nameTarget
    const elementPersonId = this.person_idTarget
    const elementCreatePerson = this.create_personTarget
    const elementMessage = this.messageTarget
    let regexpNumDoc

    if (elementTypeDoc.value == 'dni') {
      regexpNumDoc = /^\d{8}$/
    } else if (elementTypeDoc.value == 'foreigner_card') {
      regexpNumDoc = /^[a-zA-Z0-9]{1,20}$/
    }

    if (numDoc && regexpNumDoc.test(numDoc)) {
      const headers = new Headers({
        Accept: 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      })
      const url = `/admin2/personas/search.json?type_doc=${elementTypeDoc.value}&num=${numDoc}`

      const fetchOptions = {
        method: 'GET',
        headers
      }

      fetch(url, fetchOptions)
        .then(response => {
          if (response.ok) {
            console.log('ok')
            return response.json()
          } else {
            throw 'Error en la llamada Ajax'
          }
        })
        .then(json => {
          const person = json
          if (Object.keys(person).length === 0) {
            elementName.value = null
            elementName.disabled = false
            elementPersonId.value = null
            elementCreatePerson.value = true
            elementMessage.classList.add('hidden')
          } else {
            elementName.value = person.name
            elementName.disabled = true
            elementPersonId.value = person.id
            elementCreatePerson.value = false
            elementMessage.classList.remove('hidden')
          }
        })
        .catch(err => console.log(err))
    } else {
      elementName.value = null
      elementName.disabled = true
      elementPersonId.value = null
      elementCreatePerson.value = true
      elementMessage.classList.add('hidden')
    }
  }

  clear() {
    this.num_docTarget.value = null
    this.nameTarget.value = null
    this.nameTarget.disabled = false
    this.person_idTarget.value = null
    this.create_personTarget.value = true
    this.messageTarget.classList.add('hidden')

    if (this.type_docTarget.value == 'dni') {
      this.num_docTarget.maxLength = '8'
    } else if (this.type_docTarget.value == 'foreigner_card') {
      this.num_docTarget.maxLength = '20'
    }
  }
}
