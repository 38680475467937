import React, { Component } from 'react'

export default class Button extends Component {
  render() {
    const { url, title } = this.props.contentState.getEntity(this.props.entityKey).getData()
    return (
      <span style={styles.button}>
        <a style={styles.anchor} href={url}>
          {title}
        </a>
      </span>
    )
  }
}

const styles = {
  button: {
    display: 'block',
    padding: '20px 0 10px'
  },
  anchor: {
    cursor: 'pointer',
    border: '1px solid #e25959',
    background: '#e25959',
    transition: 'all 0.3s ease',
    color: '#fff',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    height: '40px',
    lineHeight: '40px',
    padding: '0 14px',
    boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    fontWeight: '400',
    textTransform: 'uppercase',
    letterSpacing: '.025em',
    textDecoration: 'none',
    fontSize: '14px',
    textAlign: 'center'
  }
}
