import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['Section']
  showhide(event) {
    let idTopicMad = this.scope.element.dataset.mad
    if (event.detail.value == idTopicMad && this.hasSectionTarget) {
      this.SectionTarget.classList.toggle('hidden')
    }
  }
}
