import { CompositeDecorator } from 'draft-js'

import Link from './link/index'
import StrategyLink from './link/strategy'

import Image from './image/index'
import StrategyImage from './image/strategy'

import Jumbotron from './jumbotron/index'
import StrategyJumbotron from './jumbotron/strategy'

import Mac from './mac/index'
import StrategyMac from './mac/strategy'

import Button from './button'
import StrategyButton from './button/strategy'

const decorator = new CompositeDecorator([
  {
    strategy: StrategyLink,
    component: Link
  },
  {
    strategy: StrategyImage,
    component: Image
  },
  {
    strategy: StrategyJumbotron,
    component: Jumbotron
  },
  {
    strategy: StrategyButton,
    component: Button
  }
])

/*
const decorator = [
  {
    strategy: StrategyLink,
    component: Link
  },
  {
    strategy: StrategyImage,
    component: Image,
  }
];
*/

export default decorator
