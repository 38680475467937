import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    this.adjustPlaceholder()
    window.addEventListener('resize', this.adjustPlaceholder.bind(this))
  }

  disconnect() {
    window.removeEventListener('resize', this.adjustPlaceholder.bind(this))
  }

  adjustPlaceholder() {
    if (window.innerWidth < 640) {
      this.inputTarget.placeholder = 'Busca lo que necesites.'
    } else {
      this.inputTarget.placeholder = 'Busca lo que necesites. Ejemplo: Abrir un negocio'
    }
  }
}
