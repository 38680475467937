import { Controller } from 'stimulus'
import infoIcon from 'images/info.svg'
import importantIcon from 'images/icons/importante.svg'

export default class extends Controller {
  static targets = ['selectInstitution', 'contentErrorNotices', 'containerOrder', 'inputNoticeId']

  connect() {
    this.attachChangeListener('.js-started-input', this.handleDatesChange.bind(this))
    this.attachChangeListener('.js-ended-input', this.handleDatesChange.bind(this))
  }

  attachChangeListener(selector, callback) {
    const inputs = this.element.querySelectorAll(selector)
    inputs.forEach(input => {
      input.addEventListener('change', callback)
    })
  }

  async handleDatesChange() {
    this.clearErrorNotices()

    const institution_id = this.selectInstitutionTarget.value
    const notice_id = this.inputNoticeIdTarget.value

    if (institution_id == '') return

    const start_date = this.getDate('.js-started-input')
    const end_date = this.getDate('.js-ended-input')

    const response = await fetch(
      `/admin2/avisos/count_for_institution?notice_id=${notice_id}&institution_id=${institution_id}&start_date=${start_date}&end_date=${end_date}`,
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )

    const notices = await response.json()

    if (!response.ok) {
      this.showErrorNotices([], importantIcon, 'bg-red-250', 'text-red-300', notices.error)
      return
    }

    if (notices.length === 1) {
      this.containerOrderTarget.classList.remove('hidden')
      this.showErrorNotices(
        notices,
        infoIcon,
        'bg-blue-310',
        'text-gray-900',
        'Tienes un cruce de fechas con otro aviso. Puedes mantener hasta 2 avisos en simultáneo o cambiar las fechas de publicación para que no exista el cruce.'
      )
    } else if (notices.length === 2) {
      this.showErrorNotices(
        notices,
        importantIcon,
        'bg-red-250',
        'text-red-300',
        'Si necesitas mantener este aviso publicado, debes cambiar las fechas este o de los otros 2 avisos. Ten en cuenta que solo puedes publicar hasta 2 avisos en simulatáneo con un solo cruce de fechas.'
      )
    }
  }

  showErrorNotices(notices, iconSrc, bgColorClass, textColorClass, textError) {
    const html = this.generateErrorNoticesHTML(notices, iconSrc, textError)
    this.renderErrorNotices(html, bgColorClass, textColorClass)
  }

  generateErrorNoticesHTML(notices, iconSrc, textError) {
    const icon = `<img class="mr-8" src="${iconSrc}" />`
    const noticesHTML = notices
      .map(
        notice =>
          `<p><strong>${notice.name}: Del ${notice.started} al ${notice.ended}</strong> ` +
          `<a href="/admin2/avisos/${notice.id}/edit" class="underline ml-2">Ver aviso</a></p>`
      )
      .join('')

    return `<div class="flex"><div class="icon">${icon}</div><div class="text-error"><p>${textError}</p> <div class="mt-4">${noticesHTML}</div></div></div>`
  }

  renderErrorNotices(html, bgColorClass, textColorClass) {
    this.contentErrorNoticesTarget.className = `flash-error w-3/5 inline-block ${bgColorClass} p-6 ${textColorClass} align-top`
    this.contentErrorNoticesTarget.innerHTML = html
  }

  clearErrorNotices() {
    this.contentErrorNoticesTarget.className = ''
    this.contentErrorNoticesTarget.innerHTML = ''
    this.containerOrderTarget.classList.add('hidden')
  }

  getDate(selector) {
    const inputs = this.element.querySelectorAll(selector)
    const [day, month, year] = Array.from(inputs).map(input => input.value)

    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  }

  handleOpenModal(event) {
    const modalId = event.target.dataset.modalId
    const modal = document.getElementById(modalId)
    const modalBackdrop = document.getElementById(modalId + '-backdrop')
    if (modal && modalBackdrop) {
      modal.classList.toggle('hidden')
      modalBackdrop.classList.toggle('hidden')
    }
  }
}
