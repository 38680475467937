import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['extra', 'seeMore', 'seeLess', 'arrowDown', 'arrowUp']

  connect() {
    if (!this.buttonTarget) return

    if (!this.hasExtraTarget || this.extraTarget.childElementCount === 0) {
      this.buttonTarget.classList.add('hidden')
    }
  }

  toggle() {
    this.extraTarget.classList.toggle('hidden')
    this.seeMoreTarget.classList.toggle('hidden')
    this.seeLessTarget.classList.toggle('hidden')
    this.arrowDownTarget.classList.toggle('hidden')
    this.arrowUpTarget.classList.toggle('hidden')
  }

  copyUrl(event) {
    const url = event.currentTarget.getAttribute('data-url')
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert('URL copiada al portapapeles')
      })
      .catch(err => {
        console.error('Error al copiar la URL: ', err)
      })
  }
}
