import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'titleInput']

  updateTitle() {
    const selectedOption = this.inputTarget.selectedOptions[0]
    if (selectedOption) {
      const title = selectedOption.textContent
      this.titleInputTarget.value = title
    }
  }
}
