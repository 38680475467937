import React, { Component, Fragment } from 'react'

export default class CodeGuide extends Component {
  render() {
    return (
      <Fragment>
        <a
          href="https://guias.servicios.gob.pe/rediseno-pagina-institucionales/uso-cms/codigos-especiales"
          target="_blank"
          className="a-btn ml-auto btn-code-guide"
          title="Ver guías de codigos"
        >
          Ver guías de codigos
        </a>
      </Fragment>
    )
  }
}
