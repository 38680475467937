import { Controller } from 'stimulus'
import Rails from 'rails-ujs'
import { mountEditor } from '../../components/html_draft_editor'

export default class extends Controller {
  static targets = ['addButton', 'boxType', 'boxPlaceholder', 'importantPositionContainer', 'importantPosition']
  IMPORTANT_BOX_ID = 26

  async add() {
    if (!this.boxTypeTarget.value) return
    if (this.boxTypeTarget.value == 26 && !this.importantPositionTarget.value) return
    const importantPosition = this.importantPositionTarget.value
    const kind = this.data.get('kind')
    const pageId = this.data.get('page_id')
    const modalities = this.data.get('modalities')
    const count = document.querySelectorAll('.js-box-fields').length
    const res = await fetch(
      `/admin2/paginas/box_fields?box_type_id=${
        this.boxTypeTarget.value
      }&count=${count}&kind=${kind}&position_important_box=${importantPosition}${pageId ? `&page_id=${pageId}` : ''}${
        modalities ? `&modalities=${modalities}` : ''
      }`
    )
    const { data } = await res.json()
    const div = document.createElement('div')
    div.innerHTML = data
    const formsElement = div.querySelector('.js-box-fields')
    this.boxPlaceholderTarget.insertAdjacentElement('beforebegin', formsElement)
    const id = `page_boxes_attributes_${count}_content_json`
    mountEditor(document.getElementById(id))
    if (!this.boxTypeTarget.value == 26) {
      this.importantPositionContainerTarget.classList.add('hidden')
    }
    if (this.boxTypeTarget.value == this.IMPORTANT_BOX_ID) this.boxTypeTarget.remove(this.boxTypeTarget.selectedIndex)
    document.getElementById('select_box_type').dispatchEvent(new Event('change'))
  }

  changeKind() {
    if (event.target.value == 26) {
      this.importantPositionContainerTarget.classList.remove('hidden')
      this.importantPositionContainerTarget.classList.add('block')
    } else {
      this.importantPositionContainerTarget.classList.add('hidden')
    }
  }
}
