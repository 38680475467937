export const clickEvent = () => ('ontouchstart' in document.documentElement ? 'touchstart' : 'click')

export const pushState = url => {
  history.pushState(
    {
      turbolinks: {}
    },
    '',
    url
  )
}

export const createElementFromHTML = htmlString => {
  var div = document.createElement('div')
  div.innerHTML = htmlString.trim()
  return div.firstChild
}
