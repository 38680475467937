import React from 'react'
import StyleButton from './style_button'

const BLOCK_TYPES = [
  /* {
    label: 'H1',
    style: 'header-one'
  },*/
  {
    label: 'H2',
    style: 'header-two',
    class: 'a-icon-color',
    icon: 'title',
    iconize: true
  },
  {
    label: 'H3',
    style: 'header-three',
    class: 'a-icon-color',
    icon: 'title',
    iconize: true
  },
  /*{
    label: 'H4',
    style: 'header-four'
  },
  {
    label: 'H5',
    style: 'header-five'
  },*/
  {
    label: 'Paso a paso',
    style: 'header-six',
    class: 'a-icon-color label',
    labelize: true
  },
  {
    label: 'UL',
    style: 'unordered-list-item',
    class: 'a-icon-color',
    icon: 'ul',
    iconize: true
  },
  {
    label: 'OL',
    style: 'ordered-list-item',
    class: 'a-icon-color',
    icon: 'ol',
    iconize: true
  }
  //{ label: 'Blockquote', style: 'blockquote' },
  //{ label: 'Code Block', style: 'code-block' },
]

const BlockStyleControls = props => {
  const { editorState, isSimpleBox, isRequirementBox, blackListControls } = props
  const selection = editorState.getSelection()
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()

  let filteredBlockTypes = BLOCK_TYPES

  if (isSimpleBox) {
    filteredBlockTypes = BLOCK_TYPES.filter(block => block.label != 'Paso a paso')
  }

  if (isRequirementBox) {
    filteredBlockTypes = filteredBlockTypes.filter(block => block.label != 'H2')
  } else {
    filteredBlockTypes = filteredBlockTypes.filter(block => block.label != 'H3')
  }

  return (
    <div className="RichEditor-controls">
      {filteredBlockTypes.map(
        type =>
          !blackListControls.includes(type.label) && (
            <StyleButton
              key={type.label}
              active={type.style === blockType}
              label={type.label}
              onToggle={props.onToggle}
              iconize={type.iconize}
              labelize={type.labelize}
              style={type.style}
              icon={type.icon}
              class={type.class}
            />
          )
      )}
    </div>
  )
}

export default BlockStyleControls
