import React, { Component, Fragment } from 'react'
import axios from 'axios'

export default class MAC extends Component {
  state = {
    show: false
  }

  open = async event => {
    this.setState({
      show: true
    })
    const pageId = document.querySelector('#page-id-for-boxes')?.dataset.id
    const response = await axios.get(`/admin/pages/${pageId}/macs_edit.html`)
    document.querySelector('.MACModalBody').innerHTML = response.data
  }

  close = event => {
    event.preventDefault()
    this.setState({
      show: false
    })
  }

  dialog = () => {
    return (
      <div className="block draftModal">
        <div className="modalBody MACModalBody row row-input" />
        <div className="">
          <button className="a-btn" onMouseDown={this.close}>
            Cerrar
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { show } = this.state
    return (
      <Fragment>
        <button type="button" className="a-btn" onMouseDown={this.open}>
          <span title="Inserta Asigna o quita MAC">MAC's</span>
          <span>&nbsp;</span>
          <span className={'a-icon-color icon-down_s'} title="Asigna o quita MAC" />
        </button>
        {show && this.dialog()}
      </Fragment>
    )
  }
}
