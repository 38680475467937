import React from 'react'
import { convertToHTML } from 'draft-convert'

const formatURL = url => {
  if (url.startsWith('mailto:')) return { url, self: false }
  const internalURL = 'www.gob.pe'
  const hasHTTP = url[0] === '/' || url.search('https://') >= 0 || url.search('http://') >= 0
  const urlFull = hasHTTP ? url : 'http://' + url
  const regExt = /\.[0-9a-z]+$/i
  const isPDF = regExt.exec(url) && regExt.exec(url)[0].toLowerCase() === '.pdf'
  const isInternal = urlFull.search(internalURL) >= 0 || url[0] === '/'
  return { self: isInternal && !isPDF, url: urlFull }
}

export default tagorigin =>
  convertToHTML({
    blockToHTML: block => {
      if (block.type === 'atomic') {
        return { start: '<jump>', end: '</jump>' }
      }
      if (block.type === 'PARAGRAPH') {
        return <p id={block.key} />
      }
      if (block.type === 'unstyled') {
        return <p id={block.key} />
      }
    },
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'LINK') {
        const { url } = entity.data
        const format = formatURL(url)
        return (
          <a
            href={format.url}
            className="track-ga-click"
            target={format.self ? '_self' : '_blank'}
            data-origin={tagorigin || 'link-inside-page'}
          >
            {originalText}
          </a>
        )
      }

      if (entity.type === 'IMAGE') {
        return {
          start: `<img src='${entity.data['src']}'>`,
          end: '</img>',
          empty: ''
        }
      }

      if (entity.type === 'BUTTON') {
        const { url } = entity.data
        const format = formatURL(url)
        return (
          <span className="boton">
            <a
              href={format.url}
              className="track-ga-click button-content-page"
              target={format.self ? '_self' : '_blank'}
              data-origin="link-inside-page"
            >
              {entity.data.title}
            </a>
          </span>
        )
      }

      if (entity.type === 'JUMP') {
        return (
          <div className="holidays">
            <div className="jumbotron">
              <p>{entity.data.pText}</p>
              <h1>{entity.data.h1Text}</h1>
              <h2>{entity.data.h2Text}</h2>
            </div>
          </div>
        )
      }
      return originalText
    }
  })
