import React, { Component } from 'react'
import TitleIcon from 'images/icons/format_size.svg'
import UlIcon from 'images/icons/format_list_bulleted.svg'
import OlIcon from 'images/icons/format_list_numbered.svg'

class StyleButton extends Component {
  onToggle = e => {
    e.preventDefault()
    this.props.onToggle(this.props.style)
  }

  render() {
    let className = ''
    let { label, icon, active } = this.props
    if (this.props.labelize == true) className = this.props.class
    if (active) className += ' active '
    if (this.props.iconize == true) {
      className += this.props.class
      label = ''
    }

    const icons = {
      title: TitleIcon,
      ul: UlIcon,
      ol: OlIcon
    }

    return (
      <button type="button" className={`a-btn ${active ? 'active' : ''}`} onClick={this.onToggle}>
        <span className={className}>{icon ? <img src={icons[icon]} /> : label}</span>
      </button>
    )
  }
}

export default StyleButton
