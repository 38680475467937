import { Controller } from 'stimulus'

export default class extends Controller {
  // use target element to disable actionText
  static targets = ['element']
  connect() {
    const buttonGroups = document.querySelectorAll(
      'div[data-controller=trix-not-allow-attach-documment] > trix-toolbar > .trix-button-row'
    )
    buttonGroups.forEach(group => {
      const fileTools = group.querySelector('.trix-button-group--file-tools')
      setTimeout(() => fileTools.remove(), 1)
      if (this.hasElementTarget) {
        if (this.elementTarget.getAttribute('disabled')) {
          this.elementTarget.contentEditable = false
          this.elementTarget.classList.add('input_like', 'disabled')
          group.remove()
        }
      }
    })

    this.elementTarget.addEventListener('trix-attachment-add', function (event) {
      event.attachment.remove()
    })
  }
}
