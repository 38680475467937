import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element']

  disableOnCheck(event) {
    this.elementTargets.forEach(el => {
      if (event.target.checked) {
        this.disableElement(el)
      } else {
        this.enableElement(el)
      }
    })
  }

  enableOnCheck(event) {
    this.elementTargets.forEach(el => {
      if (event.target.checked) {
        this.enableElement(el)
      } else {
        this.disableElement(el)
      }
    })
  }

  enableElement(el) {
    if (el.choicesDocuments) {
      el.choicesDocuments.enable()
    } else if (el.classList.contains('js-content-association')) {
      el.querySelectorAll('.form-control').forEach(el => el.removeAttribute('disabled'))
      el.querySelectorAll('.js-inputs-fields-add').forEach(el => el.classList.remove('disabled'))
    } else if (el.classList.contains('js-input-month-year')) {
      el.removeAttribute('disabled')
      el.classList.remove('disabled')
      el.parentNode.querySelectorAll('input[type=hidden]').forEach(el => el.removeAttribute('disabled'))
    } else {
      el.removeAttribute('disabled')
    }
  }

  disableElement(el) {
    if (el.choicesDocuments) {
      el.choicesDocuments.disable()
    } else if (el.classList.contains('js-content-association')) {
      el.querySelectorAll('.form-control').forEach(el => el.setAttribute('disabled', 'disabled'))
      el.querySelectorAll('.js-inputs-fields-add').forEach(el => el.classList.add('disabled'))
    } else if (el.classList.contains('js-input-month-year')) {
      el.setAttribute('disabled', 'disabled')
      el.parentNode.querySelectorAll('input[type=hidden]').forEach(el => el.setAttribute('disabled', 'disabled'))
    } else {
      el.setAttribute('disabled', 'disabled')
    }
  }
}
