import { Controller } from 'stimulus'
import Rails from 'rails-ujs'
import { mountEditor } from '../../components/html_draft_editor'

const modalitiesJson = JSON.parse(
  '[{"id":2,"name":"Online","icon":"online","created_at":"2017-06-02T16:46:17.346-05:00","updated_at":"2019-03-01T15:41:13.577-05:00","order":1,"note":null},{"id":1,"name":"Presencial","icon":"inperson","created_at":"2017-06-02T16:45:45.418-05:00","updated_at":"2020-09-08T16:31:47.102-05:00","order":2,"note":"También puedes hacerlo presencialmente:"},{"id":4,"name":"Centros MAC","icon":"mac","created_at":"2017-06-02T16:47:41.205-05:00","updated_at":"2018-09-20T14:56:10.762-05:00","order":3,"note":"También puedes hacerlo en Centros MAC:"},{"id":7,"name":"Teléfono","icon":"phone","created_at":"2017-06-07T15:49:52.415-05:00","updated_at":"2019-01-21T17:58:36.678-05:00","order":4,"note":"También puedes hacerlo por teléfono:"},{"id":5,"name":"PIAS","icon":"pias","created_at":"2017-06-06T10:08:03.195-05:00","updated_at":"2018-09-05T12:05:45.668-05:00","order":4,"note":"También puedes hacerlo en PIAS:"},{"id":6,"name":"Aeropuerto","icon":"airport","created_at":"2017-06-06T12:01:17.419-05:00","updated_at":"2018-09-05T12:05:49.717-05:00","order":5,"note":"También puedes hacerlo en el Aeropuerto:"},{"id":3,"name":"Módulo","icon":"module","created_at":"2017-06-02T16:47:33.569-05:00","updated_at":"2019-01-15T12:22:20.943-05:00","order":6,"note":"También puedes hacerlo en Módulo:"},{"id":9,"name":"","icon":"","created_at":"2019-03-04T14:32:51.888-05:00","updated_at":"2019-03-04T14:32:51.888-05:00","order":null,"note":""}]'
)
const html = `<div class="flex w-fit divide-x-2 border-t border-l border-r border-gray-900"><div class="flex-1 text-center border-gray-900 px-1 py-2 cursor-pointer" data-admin2--box-grouper="2" data-target="admin2--box.tab">Online</div><div class="flex-1 text-center border-gray-900 px-1 py-2 cursor-pointer" data-admin2--box-grouper="1" data-target="admin2--box.tab">Presencial</div><div class="flex-1 text-center border-gray-900 px-1 py-2 cursor-pointer" data-admin2--box-grouper="4" data-target="admin2--box.tab">Centros MAC</div><div class="flex-1 text-center border-gray-900 px-1 py-2 cursor-pointer" data-admin2--box-grouper="7" data-target="admin2--box.tab">Teléfono</div><div class="flex-1 text-center border-gray-900 px-1 py-2 cursor-pointer" data-admin2--box-grouper="5" data-target="admin2--box.tab">PIAS</div><div class="flex-1 text-center border-gray-900 px-1 py-2 cursor-pointer" data-admin2--box-grouper="6" data-target="admin2--box.tab">Aeropuerto</div><div class="flex-1 text-center border-gray-900 px-1 py-2 cursor-pointer" data-admin2--box-grouper="3" data-target="admin2--box.tab">Módulo</div><div class="flex-1 text-center border-gray-900 px-1 py-2 cursor-pointer" data-admin2--box-grouper="9" data-target="admin2--box.tab"></div></div>`

export default class extends Controller {
  static targets = ['box', 'modalityInput', 'tab']

  changeTab(event) {
    const clickedIndex = event.target.dataset.index
    this.doChange(clickedIndex)
  }

  doChange(index) {
    this.boxTargets.forEach(element => element.classList.add('hidden'))
    this.boxTargets.find(b => b.dataset.index == index).classList.remove('hidden')
    this.tabTargets.forEach(element => element.removeAttribute('aria-selected'))
    this.tabTargets.find(b => b.dataset.index == index).setAttribute('aria-selected', 'true')
  }

  async addPaso(event) {
    const modalities_id = []
    this.boxTargets.forEach(element => {
      let modality_id = element.dataset.modality_id
      if (typeof modality_id === 'undefined') {
        modality_id = element.getElementsByTagName('select')[0].value
      }
      modalities_id.push(modality_id)
    })

    const tabsCount = this.tabTargets.length
    const boxCount = this.boxTargets.length
    const pageId = event.currentTarget.dataset.pageId || ''
    const newTab = `<li class="flex-1 text-center px-4 py-2 cursor-pointer max-w-12" data-action="click->admin2--box-tabs#changeTab" data-index="${tabsCount}" data-target="admin2--box-tabs.tab">Nueva modalidad</li>`
    const lastTab = this.tabTargets[tabsCount - 1]
    lastTab.insertAdjacentHTML('afterend', newTab)

    const count = document.querySelectorAll('.js-box-fields').length
    const kind = this.data.get('kind')

    const res = await fetch(
      `/admin2/paginas/box_fields?box_type_id=24&page_id=${pageId}&index=${boxCount}&count=${count}&kind=${kind}&modalities_id=${modalities_id}`
    )
    const { data } = await res.json()
    const div = document.createElement('div')
    div.innerHTML = data
    const formsElement = div.querySelector('.js-box-fields')

    const lastBox = this.boxTargets[boxCount - 1]
    lastBox.insertAdjacentElement('afterend', formsElement)

    const id = `page_boxes_attributes_${count}_content_json`
    mountEditor(document.getElementById(id))
    this.doChange(boxCount)
  }
}
