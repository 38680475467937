import { Controller } from 'stimulus'
import * as $ from 'jquery'
import qs from 'query-string'
import Choices from 'choices.js'
import pDebounce from '../../lib/p-debounce'
export default class extends Controller {
  static targets = ['itemsInput', 'institution']

  connect() {
    let institution_id

    if (this.hasInstitutionTarget) {
      institution_id = this.institutionTarget?.value
    }

    const params = qs.parse(this.url?.split('?')?.[1])

    const stringParams = qs.stringify({ ...params, institution_id })

    this.url = `/admin2/categorias/search.json?${stringParams}`

    this.choicesThemes = this.itemsInputTargets.map(el => {
      const choices = el.dataset.choices ? JSON.parse(el.dataset.choices) : []
      return new Choices(el, {
        removeItemButton: true,
        noChoicesText: 'No hay opciones para elegir',
        noResultsText: 'No se han encontrado resultados',
        itemSelectText: 'Seleccionar',
        placeholder: true,
        placeholderValue: 'Búsqueda de resolución',
        searchResultLimit: 50,
        choices,
        classNames: { containerInner: 'choices__inner__custom', input: 'choices__input__custom' }
      })
    })

    const handleSearch = async event => {
      try {
        if (!this.url) return
        const params = qs.parse(this.url?.split('?')?.[1])
        const stringParams = qs.stringify({ ...params, search: event.detail.value })
        this.url = `${this.url?.split('?')[0]}?${stringParams}`
        const response = await fetch(this.url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        const items = await response.json()
        const options = items.map(item => {
          return { value: item.id, label: item.label }
        })
        this.choicesThemes.forEach(element => element.setChoices(options, 'value', 'label', true))
      } catch (err) {
        console.error(err)
      }
    }

    const debouncedSearch = pDebounce(handleSearch, 500)
    this.itemsInputTargets.forEach(element => element.addEventListener('search', debouncedSearch, false))
  }

  handleInstitutionChange(event) {
    const institution_id = event.target.value
    this.changeSegments(institution_id)
    this.changeThemes(institution_id)
  }

  changeSegments(institution_id) {
    const ajaxUrlSegments = '/admin2/busquedas-externas/institution_segments.json'
    if (institution_id) {
      $.ajax({
        type: 'GET',
        url: ajaxUrlSegments,
        data: `institution_id=${institution_id}`,
        success: data => {
          if (data.length > 0) {
            document.getElementById('section-segmets-admins').classList.remove('hidden')
            document.getElementById('fake_search_document_segment_id').innerHTML =
              '<option value="">Seleccionar</option>'
            this.refreshDropdownValues(data)
          } else {
            document.getElementById('section-segmets-admins').classList.add('hidden')
            document.getElementById('fake_search_document_segment_id').innerHTML =
              '<option value="">Seleccionar</option>'
          }
        }
      })
    }
  }

  refreshDropdownValues(data) {
    for (var i = 0; i < data.length; i++) {
      var opt = data[i]
      document.getElementById('fake_search_document_segment_id').innerHTML +=
        '<option value="' + opt.id + '">' + opt.name + '</option>'
    }
  }

  changeThemes(institution_id) {
    const params = qs.parse(this.url?.split('?')?.[1])
    const stringParams = qs.stringify({ ...params, institution_id: institution_id })
    this.url = `${this.url?.split('?')[0]}?${stringParams}`
    this.choicesThemes.forEach(element => element?.clearStore())
  }
}
