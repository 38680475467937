import React from 'react'
import StyleButton from './style_button'

const INLINE_STYLES = [
  {
    label: 'N',
    style: 'BOLD',
    class: 'a-icon-color label is-bold',
    labelize: true
  },
  {
    label: 'K',
    style: 'ITALIC',
    class: 'a-icon-color label italic',
    labelize: true
  }
  // { label: 'Italic', style: 'ITALIC' },
  // {
  //  label: 'Underline',
  //  style: 'UNDERLINE',
  //  class: 'a-icon-color icon-126'
  // },
  // { label: 'Monospace', style: 'CODE' },
]

const InlineStyleControls = props => {
  const currentStyle = props.editorState.getCurrentInlineStyle()

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          iconize={type.iconize}
          labelize={type.labelize}
          style={type.style}
          class={type.class}
        />
      ))}
    </div>
  )
}

export default InlineStyleControls
