import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element', 'clearInput', 'changer']
  static values = ['key']

  connect() {
    this.changerTargets.forEach(select => this.showHideElements(select))
  }

  handleChange(event) {
    this.showHideElements(event.target)
  }

  showHideElements(target) {
    this.elementTargets.forEach(element => {
      element.classList.add('hidden')

      this.clearInputTargets.forEach(el => {
        if (el.dataset.key.toString() !== target.value.toString()) {
          el.value = null
        }
      })

      if (target.value === element.dataset.key) {
        element.classList.remove('hidden')
      }
    })
  }
}
