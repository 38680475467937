function strategyLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()

    if (entityKey) {
      return contentState.getEntity(entityKey).getType() === 'LINK'
    }
  }, callback)
}

export default strategyLinkEntities
