import React, { Component } from 'react'

class Link extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    const { url } = this.props.contentState.getEntity(this.props.entityKey).getData()

    return (
      <a href={url} style={styles.link}>
        {this.props.children}
      </a>
    )
  }
}

const styles = {
  link: {
    color: 'blue'
  }
}

export default Link
