import { Controller } from 'stimulus'
import qs from 'query-string'
import Choices from 'choices.js'
// const pDebounce = require('p-debounce')
import pDebounce from '../../lib/p-debounce'

export default class extends Controller {
  static targets = ['itemsInput', 'kind', 'institution']
  searchInfo = { Rule: 'normas-legales', Report: 'informes-publicaciones', Feed: 'noticias' }
  url = null

  connect() {
    let institution_id
    let url
    if (this.hasInstitutionTarget) {
      institution_id = this.institutionTarget?.value
    }
    const params = qs.parse(this.url?.split('?')?.[1])
    const kind = this.kindTargets.filter(k => k.checked)?.[0]?.value
    const stringParams = qs.stringify({ ...params, institution_id })

    if (document.getElementById('collection-group')) {
      url = `/admin2/compendios/search.json?${stringParams}&type=${kind}`
    } else {
      url = `/admin2/${this.searchInfo[kind]}/search.json?${stringParams}`
    }

    if (this.searchInfo[kind] !== undefined) this.url = url

    this.choicesDocuments = this.itemsInputTargets.map(el => {
      const choices = el.dataset.choices ? JSON.parse(el.dataset.choices) : []
      return new Choices(el, {
        shouldSort: false,
        removeItemButton: true,
        noChoicesText: 'No hay opciones para elegir',
        noResultsText: 'No se han encontrado resultados',
        itemSelectText: 'Seleccionar',
        placeholder: true,
        placeholderValue: 'Seleccionar ...',
        searchResultLimit: 50,
        choices,
        classNames: { containerInner: 'choices__inner' }
      })
    })

    const handleSearch = async event => {
      try {
        if (!this.url) return
        const params = qs.parse(this.url?.split('?')?.[1])
        const kind = event.target.dataset.kind
        const collections_element = document.getElementById(`documents_collection_${kind}_ids`)
        const excluded_collection_ids = Array.from(collections_element)
          .map(item => item.value)
          .join(',')
        const stringParams = qs.stringify({
          ...params,
          search: event.detail.value,
          excluded_collection_ids: excluded_collection_ids
        })
        this.url = `${this.url?.split('?')[0]}?${stringParams}`
        const response = await fetch(this.url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        const items = await response.json()
        const options = items.map(item => {
          return { value: item.id, label: item.label }
        })
        this.choicesDocuments.forEach(element => element.setChoices(options, 'value', 'label', true))
      } catch (err) {
        console.error(err)
      }
    }

    const debouncedSearch = pDebounce(handleSearch, 500)
    this.itemsInputTargets.forEach(element => element.addEventListener('search', debouncedSearch, false))
  }

  handleTypeChange(event) {
    this.itemsInputTargets.forEach(el => (el.value = ''))
    const stringParams = this.url?.split('?')?.[1]
    if (event.target.value === 'Rule' || event.target.value === 'Report') {
      this.url = `/admin2/${this.searchInfo[event.target.value]}/search.json?${stringParams}`
      this.itemsInputTargets
        .filter(el => el.dataset.kind === 'official_document')[0]
        .closest('.js-collection-search')
        .classList.remove('hidden')
      this.itemsInputTargets
        .filter(el => el.dataset.kind === 'feed')[0]
        .closest('.js-collection-search')
        .classList.add('hidden')
    }

    if (event.target.value === 'Feed') {
      this.url = `/admin2/${this.searchInfo[event.target.value]}/search.json?${stringParams}`
      this.itemsInputTargets
        .filter(el => el.dataset.kind === 'official_document')[0]
        .closest('.js-collection-search')
        .classList.add('hidden')
      this.itemsInputTargets
        .filter(el => el.dataset.kind === 'feed')[0]
        .closest('.js-collection-search')
        .classList.remove('hidden')
    }

    this.choicesDocuments.forEach(element => element?.clearStore())
  }

  handleTypeCollectionChange(event) {
    this.itemsInputTargets.forEach(el => (el.value = ''))
    const stringParams = this.url?.split('?')?.[1]
    const typeDocument = event.target.value
    if (typeDocument === 'Rule' || typeDocument === 'Report' || typeDocument === 'Feed') {
      this.url = `/admin2/compendios/search.json?${stringParams}&type=${typeDocument}`
      this.itemsInputTargets
        .filter(el => el.dataset.kind === 'documents_collection')[0]
        .closest('.js-collection-search')
        .classList.remove('hidden')
    }
    this.choicesDocuments.forEach(element => element?.clearStore())
  }

  handleInstitutionChange(event) {
    const params = qs.parse(this.url?.split('?')?.[1])
    const stringParams = qs.stringify({ ...params, institution_id: event.target.value })
    this.url = `${this.url?.split('?')[0]}?${stringParams}`
    this.choicesDocuments.forEach(element => element?.clearStore())
  }
}
