import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selectInstitution', 'contentByInstitution']

  async changeInstitution() {
    const institutionId = this.selectInstitutionTarget.value
    this.contentByInstitutionTargets.forEach($el => this.updateUrlSearchParams($el, institutionId))
  }

  updateUrlSearchParams(el, institutionId) {
    const url = el.dataset.url
    const [path, searchParams] = url.split('?')
    const params = new URLSearchParams(searchParams)
    params.set('institution_id', institutionId)
    el.dataset.url = `${path}?${params.toString()}`
  }
}
