//import Rails from 'rails-ujs'
import { Controller } from 'stimulus'
import * as $ from 'jquery'

export default class extends Controller {
  static targets = ['company_name', 'id', 'ruc', 'button']

  connect() {}

  search() {
    const ruc = this.rucTarget.value
    const ajax_url = this.element.getAttribute('data-ajax-url')
    if (ruc && /^\d{11}$/.test(ruc)) {
      $.ajax({
        type: 'GET',
        url: ajax_url,
        data: `ruc=${ruc}`,
        success: data => {
          let sunat_ruc = data[0]
          if (sunat_ruc) {
            this.buttonTarget.innerText = 'RUC validado'
            this.company_nameTarget.value = sunat_ruc['name']
            this.idTarget.value = sunat_ruc['id']
          } else {
            this.buttonTarget.innerText = 'RUC no encontrado o inactivo'
            this.company_nameTarget.value = null
            this.idTarget.value = null
          }
        }
      })
    } else {
      this.buttonTarget.innerText = 'RUC debe ser de 11 dígitos'
      this.idTarget.value = null
      this.company_nameTarget.value = null
    }
    setTimeout(() => {
      this.buttonTarget.innerText = 'Validar RUC'
    }, 3000)
  }
  disable() {
    console.log('ruc search disable')
    if (event.target.checked) {
      this.rucTarget.disabled = true
      this.company_nameTarget.value = null
      this.rucTarget.value = null
      this.idTarget.value = null
    } else {
      this.rucTarget.disabled = false
    }
  }
}
