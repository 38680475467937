import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.afterOrder()
  }

  upDownSection(event) {
    const section = this.findAncestor(this.element, 'js-box-collapsible-text')
    if (event.currentTarget.classList.contains('js-up-section')) {
      const previousElementSibling = this.getSibling(section, '.js-box-fields', 'previous')
      if (previousElementSibling) {
        section.parentNode.insertBefore(section, previousElementSibling)
      }
    }
    if (event.currentTarget.classList.contains('js-down-section')) {
      const nextElementSibling = this.getSibling(section, '.js-box-fields')
      if (nextElementSibling) {
        section.parentNode.insertBefore(nextElementSibling, section)
      }
    }
    this.afterOrder()
  }

  getSibling(elem, selector, typeSibling = 'next') {
    let sibling = typeSibling == 'next' ? elem.nextElementSibling : elem.previousElementSibling
    if (!selector) return sibling
    while (sibling) {
      if (sibling.matches(selector)) return sibling
      sibling = typeSibling == 'next' ? sibling.nextElementSibling : sibling.previousElementSibling
    }
  }

  findAncestor(elem, selector) {
    while ((elem = elem.parentNode) && !elem.classList.contains(selector));
    return elem
  }

  afterOrder() {
    const elements = document.querySelectorAll('.js-box-collapsible-text')
    if (!elements) return
    elements.forEach((element, index) => {
      element.querySelector('.js-box-order').value = index + 1
      element.querySelector('.js-up-section').classList.remove('hidden')
      element.querySelector('.js-down-section').classList.remove('hidden')
    })
    elements[0]?.querySelector('.js-up-section').classList.add('hidden')
    elements[elements.length - 1]?.querySelector('.js-down-section').classList.add('hidden')
  }
}
