import { Controller } from 'stimulus'
import { generateChoices } from '../../src/generate_choices'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['input', 'kind']

  connect() {
    generateChoices(this.inputTarget)
  }

  handleKindChange(event) {
    const stringParams = this.url?.split('?')?.[1]
    switch (event.target.value) {
      case 'Page':
        this.inputTarget.url = `/admin2/paginas/search.json?${stringParams}`
        break
      case 'Campaign':
        this.inputTarget.url = `/admin2/campañas/search.json?${stringParams}&with_hide_campaigns=true`
        break
      case 'Topic':
        this.inputTarget.url = `/admin2/temas-nacionales/search.json?${stringParams}`
        break
      case 'Rule':
        this.inputTarget.url = `/admin2/normas-legales/search.json?${stringParams}`
        break
      case 'Report':
        this.inputTarget.url = `/admin2/informes-publicaciones/search.json?${stringParams}`
        break
      case 'Feed':
        this.inputTarget.url = `/admin2/noticias/search.json?${stringParams}`
        break
      case 'DocumentsCollection':
        this.inputTarget.url = `/admin2/compendios/search.json?${stringParams}`
        break
      case 'Theme':
        this.inputTarget.url = `/admin2/categorias/search.json?${stringParams}&hide_theme_transversal=true`
        break
      case 'Official':
        this.inputTarget.url = `/admin2/funcionarios/search.json?${stringParams}`
        break
    }

    this.inputTarget.setAttribute('data-url', this.inputTarget.url)

    this.choicesDocuments?.clearStore()
  }
}
