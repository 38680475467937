/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Rails from 'rails-ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from 'activestorage'
import '@kollegorna/cocoon-vanilla-js'

import '../components/date_picker'

import 'controllers'
import '../components/html_draft_editor'
import '../components/tabby'
import '../components/geocomplete'
import '../components/analytics_tracker'
import '../styles/application_admin2.scss'

require('trix')
require('@rails/actiontext')
require('../src/direct_uploads')

import '../src/choices.js'
import '../src/admin2/institutionalizable'
import '../src/admin2/documents_fields'
import '../src/admin2/character_counter'
import '../src/admin2/max_inputs_cocoon'
import '../src/admin2/max_input_related_links'
import '../src/admin2/nav'
import '../src/admin2/campaign_partials'
import '../controllers/admin2/accordion_controller'
import '../src/admin2/child_pages_sorting'
import '../src/admin2/dashboard'
import '../src/admin2/configurate_trix'

require.context('../images', true)

Rails.start()
Turbolinks.start()
ActiveStorage.start()
