import React from 'react'
import ReactDOM, { render } from 'react-dom'
import { compose, withProps, lifecycle } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import SearchBox from 'react-google-maps/lib/components/places/SearchBox'
import get from 'lodash/get'

const onDrag = coordenates => {
  document.getElementById('branch_office_longitude').value = coordenates.latLng.lng()
  document.getElementById('branch_office_latitude').value = coordenates.latLng.lat()
}

const disableKeyDown = e => {
  var code = e.keyCode || e.which
  if (code == 13) {
    e.preventDefault()
  }
}

const MapWithASearchBox = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAk7mAcKrAqdTicOAX40oF4LP_tvgvu8J0&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `350px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}

      this.setState({
        bounds: null,
        center: {
          lat: parseFloat(this.props.lat),
          lng: parseFloat(this.props.lng)
        },
        markers: [
          {
            position: {
              lat: parseFloat(this.props.lat),
              lng: parseFloat(this.props.lng)
            }
          }
        ],
        onMapMounted: ref => {
          refs.map = ref
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter()
          })
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces()
          const bounds = new google.maps.LatLngBounds()

          let street_number_add = ''
          let route_add = ''
          let locality_add = ''
          let administrative_area_level_2_add = ''
          let administrative_area_level_1_add = ''

          places.forEach(place => {
            const { location } = place.geometry

            document.getElementById('branch_office_longitude').value = location.lng()
            document.getElementById('branch_office_latitude').value = location.lat()
            if (place.address_components) {
              if (place.address_components.length > 0) {
                for (let i = 0; i < place.address_components.length; i++) {
                  const e = place.address_components[i]
                  const country = e.types.indexOf('country') || true

                  const street_number = e.types.indexOf('street_number') || true
                  const route = e.types.indexOf('route') || true

                  const locality = e.types.indexOf('locality') || true
                  const administrative_area_level_2 = e.types.indexOf('administrative_area_level_2') || true
                  const administrative_area_level_1 = e.types.indexOf('administrative_area_level_1') || true

                  if (country === true) {
                    document.getElementById('branch_office_country').value = e.short_name
                  }

                  if (street_number === true) {
                    street_number_add = e.short_name
                  }

                  if (route === true) {
                    route_add = e.short_name
                  }

                  if (locality === true) {
                    locality_add = `${e.short_name}`
                  }

                  if (administrative_area_level_2 === true) {
                    administrative_area_level_2_add = `, ${e.short_name}, `
                  }

                  if (administrative_area_level_1 === true) {
                    administrative_area_level_1_add = `${e.short_name}`
                  }
                }
              }
            }

            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport)
            } else {
              bounds.extend(place.geometry.location)
            }
          })

          document.getElementById('branch_office_city').value =
            `${locality_add} ${administrative_area_level_2_add} ${administrative_area_level_1_add}`
          document.getElementById('branch_office_line1').value = `${route_add} ${street_number_add}`

          const nextMarkers = places.map(place => ({
            position: place.geometry.location
          }))

          const nextCenter = get(nextMarkers, '0.position', this.state.center)

          this.setState({
            center: nextCenter,
            markers: nextMarkers
          })

          // refs.map.fitBounds(bounds);
        }
      })
    }
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap ref={props.onMapMounted} defaultZoom={16} center={props.center} onBoundsChanged={props.onBoundsChanged}>
    <SearchBox
      types={['(cities)']}
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={props.onPlacesChanged}
    >
      <input
        type="text"
        placeholder="Distrito, provincia, departamento..."
        onKeyDown={disableKeyDown}
        style={{
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `500px`,
          height: `32px`,
          marginTop: `8px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`
        }}
      />
    </SearchBox>
    {props.markers.map((marker, index) => (
      <Marker draggable={true} onDrag={onDrag} key={index} position={marker.position} />
    ))}
  </GoogleMap>
))

document.addEventListener('turbolinks:load', () => {
  const id = document.getElementById('js-autocomplete-places')
  if (id) {
    var lat = document.getElementById('branch_office_latitude').value
    var lng = document.getElementById('branch_office_longitude').value
    if (!lat) lat = '-12.046374'
    if (!lng) lng = '-77.0427934'
    render(<MapWithASearchBox lat={lat} lng={lng} />, id)
  }
})
