import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['iconSection', 'imageSection', 'nestedFields', 'selector']

  connect() {
    this.previousValue = this.selectorTarget.value
    this.observeDomChanges()
    this.updateFields()
  }

  change(event) {
    const confirmationMessage = 'Si cambias el tipo de lista, se eliminarán todos los módulos. ¿Deseas continuar?'
    const currentModule = event.currentTarget.closest('.nested-fields')

    if (this.hasNestedFields(currentModule)) {
      if (confirm(confirmationMessage)) {
        this.removeNestedFields(currentModule)
        this.updateFields()
      } else {
        this.selectorTarget.value = this.previousValue
      }
    } else {
      this.updateFields()
    }

    this.previousValue = this.selectorTarget.value
  }

  updateFields() {
    const type = this.selectorTarget.value

    this.toggleSectionVisibility(this.iconSectionTargets, type === 'icon_list')
    this.toggleSectionVisibility(this.imageSectionTargets, type === 'image_list')
  }

  toggleSectionVisibility(targets, shouldBeVisible) {
    targets.forEach(target => {
      target.classList.toggle('hidden', !shouldBeVisible)
    })
  }

  removeNestedFields(module) {
    const nestedFields = module.querySelectorAll('fieldset.nested-fields')
    nestedFields.forEach(field => {
      const removeButtons = field.querySelectorAll('.js-inputs-fields-remove')
      removeButtons.forEach(button => {
        button.click()
      })
    })
  }

  hasNestedFields(module) {
    return module.querySelectorAll('.nested-fields').length > 0
  }

  observeDomChanges() {
    const observer = new MutationObserver(() => {
      this.updateFields()
    })

    this.nestedFieldsTargets.forEach(target => {
      observer.observe(target, { childList: true, subtree: true })
    })
  }
}
