import { generateChoices } from './../generate_choices'

document.addEventListener('turbolinks:load', event => {
  const elements = document.querySelectorAll('.js-content-association')
  elements.forEach(element => {
    // after-insert
    element.addEventListener('cocoon:after-insert', event => {
      updateAllNestedForms(element)
    })
    // after-remove
    element.addEventListener('cocoon:after-remove', event => {
      updateAllNestedForms(element)
    })
    updateAllNestedForms(element)
  })
})

const updateAllNestedForms = element => {
  let currentElement = element
  while (currentElement) {
    updateNestedForm(currentElement)
    currentElement = currentElement.closest('.js-content-association').parentElement.closest('.js-content-association')
  }

  const nestedElements = element.querySelectorAll('.js-content-association')
  nestedElements.forEach(nestedElement => {
    updateNestedForm(nestedElement)
  })

  const js_choices = document.querySelectorAll('.js-choice')
  js_choices.forEach(el => {
    if (el.offsetParent !== null) {
      generateChoices(el)
    }
  })
}

const updateNestedForm = element => {
  handleDefaultItems(element)
  disableAddButton(element)
  updateDeleteButtons(element)
}

const handleDefaultItems = element => {
  const defaultItems = parseInt(element.dataset.defaultItems) || 0
  const existingItems = element.querySelectorAll('.js-inputs-fields').length

  if (defaultItems > existingItems) {
    for (let i = existingItems; i < defaultItems; i++) {
      const addButton = element.querySelector('.js-inputs-fields-add')
      if (addButton) {
        addButton.click()
      }
    }
  }

  element.dataset.defaultItems = 0
}

const updateDeleteButtons = element => {
  const boxFields = Array.from(element.querySelectorAll('.js-inputs-fields')).filter(
    child => child.closest('.js-content-association') === element && child.style.display !== 'none'
  )
  const minItems = element.dataset.minItems || 1
  if (boxFields.length === parseInt(minItems)) {
    boxFields.forEach(child => child.querySelector('.js-inputs-fields-remove').classList.add('hidden'))
  } else {
    boxFields.forEach(child => child.querySelector('.js-inputs-fields-remove').classList.remove('hidden'))
  }
  writeLegendNumbers(boxFields)
  updateArrowsOrder()
}

const writeLegendNumbers = boxes => {
  boxes.forEach((box, index) => {
    const itemTitle = box.dataset.itemTitle
    if (itemTitle !== undefined) {
      box.querySelector('legend').textContent = `${itemTitle}${index + 1}`
    }
  })
}

const disableAddButton = element => {
  const maxItems = parseInt(element.dataset.maxItems)
  const countFields = Array.from(element.querySelectorAll(':scope > .js-inputs-fields')).filter(
    child => child.style.display !== 'none'
  )
  const style =
    countFields.length >= maxItems
      ? 'pointer-events: none; color: #919191; border: 1px solid gray'
      : 'color: #0056ac; border: 1px solid #0056ac'
  element.querySelectorAll('.js-inputs-fields-add').forEach(btnAdd => {
    const parentElement = btnAdd.closest('.js-content-association')
    if (parentElement === element) {
      btnAdd.style = style
    }
  })
}

const updateArrowsOrder = () => {
  const elements = Array.from(document.querySelectorAll('.js-item-sortable')).filter(
    child => child.style.display !== 'none'
  )
  if (!elements) return

  elements[0]?.querySelector('.js-up-button')?.classList.add('hidden')
  elements[elements.length - 1]?.querySelector('.js-down-button')?.classList.add('hidden')
}
