import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element', 'clearInput']

  toggle(event) {
    this.elementTargets.forEach((el, i) => {
      el.classList.add('hidden')

      this.clearInputTargets.forEach(e => {
        if (e.value != event.target.value) {
          console.log(e)
          e.value = null
        }
      })
    })

    if (event.target.checked) {
      this.elementTargets.forEach((el, i) => {
        let keys = el.getAttribute('data-keys').split(',')
        if (keys.includes(event.target.value)) {
          el.classList.remove('hidden')
          el.focus()
        }
      })
    }
  }
}
