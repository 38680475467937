import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['value', 'input']

  connect() {
    console.log('Conectando input_field')
  }

  display(event) {
    const fileName = event.target.value.split('\\').pop()

    if (this.valueTarget.nodeName == 'INPUT') {
      this.valueTarget.placeholder = fileName
    } else {
      this.valueTarget.innerHTML = fileName
    }
  }
}
