import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['passwordInput']

  togglePassword() {
    const passwordInput = this.passwordInputTarget
    passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password'
  }
}
