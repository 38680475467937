// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      filter: '.fixed-row',
      onEnd: this.end.bind(this)
    })

    this.initialState = Array.from(this.element.children).map((child, index) => ({
      element: child,
      isFixed: child.classList.contains('fixed-row'),
      initialIndex: index
    }))
  }

  end(event) {
    const movableRows = Array.from(this.element.children).filter(child => !child.classList.contains('fixed-row'))

    this.initialState.forEach((state, index) => {
      if (state.isFixed) {
        this.element.insertBefore(state.element, this.element.children[index])
      } else {
        const movableElement = movableRows.shift()
        if (movableElement) {
          this.element.insertBefore(movableElement, this.element.children[index])
        }
      }
    })

    const isFixedPosition = this.initialState.some(state => state.isFixed && state.initialIndex === event.newIndex)

    if (isFixedPosition) {
      return
    }

    const id = event.item.dataset.id
    const key = this.data.get('model')

    const orderedIds = Array.from(this.element.children).map((child, index) => ({
      id: child.dataset.id,
      order: index + 1
    }))

    fetch(this.data.get('url').replace(':id', id), {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      method: 'PATCH',
      body: JSON.stringify({
        [key]: {
          row_order_position: event.newIndex,
          ordered_ids: orderedIds
        }
      })
    })
  }
}
