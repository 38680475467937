import { Controller } from 'stimulus'
import editIcon from 'images/icons/themes/edit.svg'
import deleteIcon from 'images/icons/themes/delete_blue.svg'
export default class extends Controller {
  static targets = ['tree', 'children', 'delete', 'editChild']

  connect() {
    if (!!document.getElementById('tree')) {
      const children = this.childrenTargets
      const tree = document.getElementById('tree')
      const previousChild = document.querySelectorAll('.children_fields_container active')

      this.hidePreviousChild(previousChild)

      if (this.childrenTargets.length) {
        children.forEach(child => {
          if (child.value.length > 0) {
            this.appendChildrenToTree(child, tree, 'create')
          }
        })
      }
    }
  }

  setTree() {
    const tree = document.getElementById('tree')
    const addButtonText = document.getElementById('js-themes-addChildren')
    const disabledAddButton = document.getElementById('js-addButtonDisabled')
    const enabledAddButton = document.getElementById('js-addButtonEnabled')
    const childPreview = document.getElementById(`ul_${this.childrenTarget.id}`)

    if (this.childrenTarget.value.length > 0 && addButtonText) {
      this.enableAddButton(addButtonText, disabledAddButton, enabledAddButton, childPreview)
    } else if (addButtonText) {
      this.disabledAddButton(addButtonText, disabledAddButton, enabledAddButton, childPreview)
      return
    }

    this.appendChildrenToTree(this.childrenTarget, tree, 'update')
  }

  ShowHideTheme(event) {
    const child = event.target

    if (child.tagName == 'SPAN') {
      const childEdit = child.getAttribute('edit-id')
      if (childEdit && childEdit != 'parent') {
        this.ChangeFieldSet(childEdit)
        this.TextColorSelect(childEdit)
        this.ChangeImageFileName(childEdit)
      } else {
        const childDelete = child.getAttribute('delete-id')
        this.TextColorSelect(childDelete)
        this.HidenFieldsets()
        document.getElementById('block-confirm').classList.remove('hidden')
        const themeName = document.querySelector(`.theme-name-${childDelete}`).innerText
        document.getElementById('theme-delete').innerText = themeName
        document.getElementById('button-confirm').setAttribute('theme-delete-id', childDelete)
      }
    }
  }

  fakeUpdate(id) {
    const inputParentId = document.querySelector(`[value="${id}"]`).parentElement.firstChild.nextSibling
    const parentId = inputParentId.value

    const inputDepth = inputParentId.nextSibling
    const depth = inputDepth.value

    const fakeUpdate = inputDepth.nextSibling

    fakeUpdate.value = parseInt(fakeUpdate.value) + 1

    if (depth > 1) {
      this.fakeUpdate(parentId)
    }
  }

  ButtonConfirm() {
    const childDelete = document.getElementById('button-confirm').getAttribute('theme-delete-id')
    const themeSelect = document.querySelectorAll(`.theme-select-${childDelete}`)
    themeSelect.forEach(themeSelect => themeSelect.parentElement.classList.add('hidden'))
    this.AddDestroy(childDelete)
    document.getElementById('block-confirm').classList.add('hidden')
  }

  ButtonCancel() {
    document.getElementById('block-confirm').classList.add('hidden')
    this.RemoveTextColor()
  }

  AddDestroy(id) {
    const inputDestroy = document.querySelector(`[value="${id}"]`).nextElementSibling
    inputDestroy.value = 1
    this.HidenFieldsets()
    this.fakeUpdate(id)
  }
  ChangeFieldSet(id) {
    const fieldsetEdit = document.querySelector(`[value="${id}"]`)
    this.HidenFieldsets()
    fieldsetEdit.parentElement.classList.remove('hidden')
  }

  ChangeImageFileName(id) {
    const imagesetEdit = document.querySelector(`.theme-image-${id}`)
    const elements = document.querySelectorAll(`.js-input-child-image`)
    elements.forEach(element => {
      element.innerText = imagesetEdit.textContent
    })
  }

  HidenFieldsets() {
    const fieldsetContainer = document.querySelectorAll('.children_fields_container')
    fieldsetContainer.forEach(fieldsetContainer => fieldsetContainer.classList.add('hidden'))

    document.getElementById('block-confirm').classList.add('hidden')
  }

  RemoveTextColor() {
    const themeSelect = document.querySelectorAll('.theme-select')
    themeSelect.forEach(themeSelect => themeSelect.classList.remove('font-bold'))
  }

  TextColorSelect(id) {
    this.RemoveTextColor()
    if (id) {
      const themeSelectId = document.querySelectorAll(`.theme-select-${id}`)
      themeSelectId.forEach(themeSelectId => themeSelectId.classList.add('font-bold'))
    }
  }

  hideChildrenFieldSet(event) {
    const closeFieldset = event.target.parentElement
    closeFieldset.classList.add('hidden')
    this.TextColorSelect(0)
  }

  appendChildrenToTree(child, tree, action) {
    const childId = this.childId(child)

    if (this.childExists(child)) {
      this.updateChildValue(child)
    } else {
      this.createChild(tree, child, childId, action)
    }
  }

  createChild(tree, child, childId, action) {
    let action_update = ''
    if (action == 'update') {
      action_update = `<a data-action="admin2--themes--list-item#editChild" data-target="admin2--themes--list-item.edit" data-edit-id="${childId}">
                        <img src="${editIcon}" class="inline h-6 w-6 mr-2 cursor-pointer">        
                      </a>
                      <a data-action="admin2--themes--list-item#removeChild" data-target="admin2--themes--list-item.delete" data-destroy-id="${childId}">
                        <img src="${deleteIcon}" class="inline cursor-pointer">
                      </a>`
    }
    tree.innerHTML += `<li id="ul_${childId}_name" data-controller="admin2--themes--list-item" >
          <div class="bg-blue-200 text-center p-3 h-auto inline-flex -ml-2">
            <p class="element-preview inline-block px-4 md:px-8" data-target="admin2--themes--list-item.name" data-child-id="${childId}">${child.value}</p>
            ${action_update}
          <div>
      </li>`
  }

  hidePreviousChild(previousChild) {
    if (previousChild.length > 0) {
      previousChild[0].classList.add('hidden')
      previousChild[0].classList.remove('active')
      this.childrenTarget.closest('.children_fields_container').classList.remove('hidden')
      this.childrenTarget.closest('.children_fields_container').classList.add('active')
    } else {
      this.childrenTarget.closest('.children_fields_container').classList.remove('hidden')
      this.childrenTarget.closest('.children_fields_container').classList.add('active')
    }
  }

  enableAddButton(addButtonText, disabledAddButton, enabledAddButton, childPreview) {
    addButtonText.classList.remove('text-gray-500')
    addButtonText.classList.add('text-blue-500')
    disabledAddButton.classList.add('hidden')
    enabledAddButton.classList.remove('hidden')
    this.appendChildrenToTree(this.childrenTarget, tree, 'update')
  }

  disabledAddButton(addButtonText, disabledAddButton, enabledAddButton, childPreview) {
    childPreview.remove()
    addButtonText.classList.add('text-gray-500')
    addButtonText.classList.remove('text-blue-500')
    disabledAddButton.classList.remove('hidden')
    enabledAddButton.classList.add('hidden')
  }

  changeEdit(event) {
    const themeChildrenFieldset = event.target.parentElement
    const inputId = themeChildrenFieldset.querySelector('.theme_children_attributes_id')
    const inputName = themeChildrenFieldset.querySelector('.theme_children_attributes_name')
    const textareaDescription = themeChildrenFieldset.querySelector('.theme_children_attributes_description')
    const inputVideo = themeChildrenFieldset.querySelector('.theme_children_attributes_accessibility_video_url')

    const nameValue = inputName.value
    const descriptionValue = textareaDescription.value
    const idValue = inputId.value
    const videoValue = inputVideo.value

    this.changeThemeName(idValue, nameValue)
    this.changeThemeDescription(idValue, descriptionValue)
    this.changeThemeVideo(idValue, videoValue)
    this.HidenFieldsets()
    this.RemoveTextColor()

    this.fakeUpdate(idValue)
  }

  changeThemeName(id, name) {
    document.querySelector(`.theme-name-${id}`).innerText = name
  }

  changeThemeVideo(id, video) {
    document.querySelector(`.theme-accesibility-video-url-${id}`).innerText = video
  }

  changeThemeDescription(id, description) {
    let descriptionText = 'Sin descripción'
    if (description.length > 0) {
      descriptionText = description.length > 60 ? description.slice(0, 57) + '...' : description
    }
    document.querySelector(`.theme-description-${id}`).innerHTML = descriptionText
  }

  updateChildValue(child) {
    const childTreeValue = document.querySelector(`[data-child-id='${this.childId(child)}']`)

    childTreeValue.innerHTML = child.value
  }

  childId(child) {
    return child.id.slice(0, -5)
  }

  childExists(child) {
    return document.querySelectorAll(`[data-destroy-id='${this.childId(child)}']`).length > 0
  }
}
