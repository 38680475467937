import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'label']

  updateLabel() {
    const fileName = this.inputTarget.files.length
      ? this.inputTarget.files[0].name
      : 'Selecciona el archivo o arrástralo aquí'
    this.labelTarget.textContent = fileName
  }

  handleDragOver(event) {
    event.preventDefault()
    this.element.classList.add('dragover')
  }

  handleDragLeave(event) {
    event.preventDefault()
    this.element.classList.remove('dragover')
  }

  handleFileDrop(event) {
    event.preventDefault()
    this.element.classList.remove('dragover')

    const files = event.dataTransfer.files
    if (files.length > 0) {
      this.inputTarget.files = files
      this.updateLabel()
    }
  }
}
