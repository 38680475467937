import React, { Component } from 'react'
import { Modifier, RichUtils, EditorState } from 'draft-js'
import FormatClearIcon from 'images/icons/format_clear.svg'

export default class componentName extends Component {
  removeStyles = inlineStyle => {
    const { editorState, changeEditorState } = this.props
    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const styles = ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH', 'CODE']
    const removeStyles = styles.reduce((state, style) => {
      return Modifier.removeInlineStyle(state, selection, style)
    }, contentState)
    const removeBlock = Modifier.setBlockType(removeStyles, selection, 'unstyled')
    changeEditorState(RichUtils.toggleLink(EditorState.push(editorState, removeBlock), selection, null))
  }

  render() {
    return (
      <button type="button" className="a-btn" onClick={this.removeStyles}>
        <span className="a-icon-color">
          <img src={FormatClearIcon} />
        </span>
      </button>
    )
  }
}
