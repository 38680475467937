import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'checkbox', 'toggleText']

  connect() {
    this.updateLabel()
  }

  toggle(event) {
    this.updateLabel()
    this.contentTarget.classList.toggle('hidden', !this.checkboxTarget.checked)
  }

  updateLabel() {
    const isChecked = this.checkboxTarget.checked
    const activeText = this.checkboxTarget.dataset.labelActive || 'Módulo habilitado'
    const inactiveText = this.checkboxTarget.dataset.labelInactive || 'Módulo deshabilitado'

    this.toggleTextTarget.textContent = isChecked ? activeText : inactiveText
  }
}
