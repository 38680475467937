import { Controller } from 'stimulus'
import Rails from 'rails-ujs'
import Toastr from 'toastr'
import { createElementFromHTML } from '../../components/helpers'

export default class extends Controller {
  static targets = ['tile']

  submit(event) {
    const form = event.target.closest('form')
    form.addEventListener('ajax:error', evt => {
      Toastr.error(evt.detail[0])
    })
    form.addEventListener('ajax:success', evt => {
      const data = evt.detail[0]
      const { tile, notification } = data
      if (tile) {
        this.tileTarget.parentNode.replaceChild(createElementFromHTML(tile), this.tileTarget)
      }
      Toastr.success(notification)
    })
    Rails.fire(form, 'submit')
  }
}
