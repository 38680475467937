import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['visibilityIcon', 'visibilityInput', 'visibilityOn', 'visibilityOff']

  toggle_visibilty() {
    if (this.visibilityInputTarget.value == 'true') {
      this.visibilityOnTarget.classList.add('hidden')
      this.visibilityOffTarget.classList.remove('hidden')
      this.visibilityInputTarget.value = 'false'
    } else {
      this.visibilityOnTarget.classList.remove('hidden')
      this.visibilityOffTarget.classList.add('hidden')
      this.visibilityInputTarget.value = 'true'
    }
  }
}
