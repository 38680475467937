document.addEventListener('turbolinks:load', event => {
  const elements = document.querySelectorAll('nav li')
  elements.forEach(el => {
    if (el.querySelector('a.active')) el.classList.add('active')
  })
  if (!!document.getElementById('clickoutside')) {
    document.addEventListener('click', event => {
      let element = event.target
      clickOutsideMenu(element)
    })
  }
})

function clickOutsideMenu(element) {
  if (!element.closest('.clickoutside')) {
    const clickoutside = document.querySelectorAll('.clickoutside')
    clickoutside.forEach(clickoutside => {
      clickoutside.classList.remove('open')
    })
  }
  if (!element.closest('.nav-mobile')) {
    document.querySelector('.parent-1').classList.remove('openParent-1')
  }
}
