'use strict'
import React, { Component, Fragment } from 'react'
import {
  AtomicBlockUtils,
  AtomicBlock,
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  SelectionState,
  Modifier
} from 'draft-js'

import decorators from './decorators'
import convertToHTML from './convertToHTML'

/** TOOLBARS **/
import Toolbar from './toolbar/Toolbar'
import 'draft-js/dist/Draft.css'
import './styles.scss'

const sampleMarkup = {
  entityMap: {},
  blocks: [
    {
      key: Math.floor(Math.random() * Math.pow(2, 24)).toString(32), // 'gob17'
      text: '',
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }
  ]
}

class Myeditor extends Component {
  getInitialEditorState = () => {
    const { id, element } = this.props
    let savedMarkup = (element || document.getElementById(this.props.id)).value
    if (!savedMarkup) {
      savedMarkup = sampleMarkup
    } else {
      savedMarkup = JSON.parse(savedMarkup)
    }
    return EditorState.createWithContent(convertFromRaw(savedMarkup), decorators)
  }

  state = {
    editorState: this.getInitialEditorState()
  }

  onChange = (editorState, afterChangeState = () => {}) => {
    if (!this.canUserWrite(editorState)) {
      return this.setState(state => ({ editorState: state.editorState }))
    }
    this.setState({ editorState }, afterChangeState)
    const { tagorigin, id, element } = this.props
    const jsonElement = element || document.getElementById(id)
    const htmlElement = element?.previousElementSibling || document.getElementById(id.replace('_json', ''))
    const content = editorState.getCurrentContent()
    const html = convertToHTML(tagorigin)(content)
    jsonElement.value = JSON.stringify(convertToRaw(content))
    htmlElement.value = html
  }

  focus = () => this.refs.editor.focus()

  onTab = event => {
    const maxDepth = 2
    this.onChange(RichUtils.onTab(event, this.state.editorState, maxDepth))
  }

  toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType))
  }

  toggleInlineStyle = inlineStyle => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle))
  }

  handleKeyCommand = command => {
    const { editorState } = this.state
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return true
    }
    return false
  }

  changeEditorStateRaw = editorState => this.setState({ editorState })

  updateData = (data, block) => {
    const { editorState } = this.state
    const content = editorState.getCurrentContent()
    const selection = new SelectionState({
      anchorKey: block.key,
      anchorOffset: 0,
      focusKey: block.key,
      focusOffset: block.getLength()
    })
    const newContentState = Modifier.mergeBlockData(content, selection, data)
    const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data')
    this.onChange(newEditorState)
  }

  changeEditorState = (entity, genNewState = false) => {
    const { editorState } = this.state
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(...entity)
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    })
    this.onChange(
      genNewState
        ? genNewState(newEditorState, entityKey)
        : AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '),
      this.focus
    )
  }

  canUserWrite = editorState => {
    const { maxLength } = this.props
    if (maxLength) {
      const content = editorState.getCurrentContent()
      const text = content.getPlainText()
      return text.length <= maxLength
    }
    return true
  }

  blockRendererFn = contentBlock => {
    const type = contentBlock.getType()
    if (type !== 'atomic') return null
    return {
      component: AtomicBlock,
      editable: true,
      props: {
        setReadOnly: this.setReadOnly,
        updateData: this.updateData
      }
    }
  }

  render() {
    const { editorState } = this.state
    const {
      isSimpleBox,
      isRequirementBox,
      showJump,
      onlyLinkBox,
      hideToolbar = false,
      blackListControls,
      maxLength = 0,
      placeholder = 'Escribe algo...',
      ...props
    } = this.props
    return (
      <Fragment>
        <div className="bg-white border-2 border-gray-750 pt-20 px-3 pb-8 relative custom-rich-text">
          {!hideToolbar && (
            <Toolbar
              blackListControls={blackListControls}
              showJump={showJump}
              isSimpleBox={isSimpleBox}
              isRequirementBox={isRequirementBox}
              onlyLinkBox={onlyLinkBox}
              editorState={editorState}
              changeEditorState={this.changeEditorState}
              toggleInlineStyle={this.toggleInlineStyle}
              toggleBlockType={this.toggleBlockType}
              changeEditorStateRaw={this.changeEditorStateRaw}
            />
          )}
          <div className="editor">
            <Editor
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              blockRendererFn={this.blockRendererFn}
              onTab={this.onTab}
              spellCheck={true}
              onChange={this.onChange}
              placeholder={placeholder}
              ref="editor"
            />
          </div>
        </div>
        {maxLength > 0 && (
          <p className="text-right">
            <span>{maxLength - editorState.getCurrentContent().getPlainText().length} caracteres</span>
          </p>
        )}
      </Fragment>
    )
  }
}

export default Myeditor
