import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'control']

  connect() {
    this.change()
  }

  change() {
    const inputValue = this.inputTarget.value
    if (inputValue === '') {
      this.controlTarget.classList.add('hidden')
    } else {
      this.controlTarget.classList.remove('hidden')
    }
  }
}
