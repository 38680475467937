import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selector']

  handleHasParent(event) {
    if (event.target.value === 'yes' || event.target.value === 'true') {
      this.selectorTarget.classList.remove('hidden')
    } else {
      this.selectorTarget.classList.add('hidden')
    }
  }
}
