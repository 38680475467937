import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['parent1', 'headerUser', 'headerSubaction', 'headerNotification']

  toggleParent1(event) {
    this.parent1Target.classList.toggle('openParent-1')

    if (document.querySelector('.js-title-arrow-menu')) {
      this.toggleTitle()
    }
  }
  toggleParent2(event) {
    setTimeout(function () {
      let element = event.target
      if (element.tagName != 'A') element = element.parentElement

      if (element.tagName == 'A') {
        let parent = element.parentElement
        let a_select = parent.firstChild
        let a_focus = a_select.nextElementSibling
        let class_element = '.sublevel-1'
        if (parent.classList.contains('sublevel-2')) class_element = '.sublevel-2'

        parent.classList.toggle('open')
        parent.classList.toggle('hidden')
        parent.lastChild.classList.toggle('hidden')
        a_select.classList.toggle('hiddenFocus')
        a_focus.classList.toggle('hiddenFocus')

        const submenu = document.querySelectorAll(class_element)
        submenu.forEach(submenu => submenu.classList.toggle('hidden'))

        if (class_element == '.sublevel-2') {
          parent.parentElement.parentElement.firstElementChild.classList.toggle('hiddenParent')
        }
      }
    }, 100)
  }
  dropdownHeaderUser(event) {
    this.headerUserTargets.forEach((el, i) => {
      el.classList.toggle('open')
    })
    this.headerSubactionTargets.forEach((el, i) => {
      el.classList.remove('open')
    })
    this.headerNotificationTarget.classList.remove('open')
  }

  dropdownHeaderSubaction(event) {
    this.headerSubactionTargets.forEach((el, i) => {
      el.classList.toggle('open')
    })
    this.headerUserTargets.forEach((el, i) => {
      el.classList.remove('open')
    })
    this.headerNotificationTarget.classList.remove('open')
  }
  dropdownHeaderNotification(event) {
    this.headerSubactionTargets.forEach((el, i) => {
      el.classList.remove('open')
    })

    this.headerUserTargets.forEach((el, i) => {
      el.classList.remove('open')
    })
    this.headerNotificationTarget.classList.toggle('open')
  }

  toggleTitle() {
    const titlesArrowMenu = document.querySelectorAll('.js-title-arrow-menu')
    titlesArrowMenu.forEach(title => title.classList.toggle('hidden'))
  }
}
