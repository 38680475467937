import { Controller } from 'stimulus'
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ['element', 'officialInput', 'nameInput', 'withOfficial']

  change(event) {
    const elementPhoto = document.querySelector('.js-councilor-photo-name')
    const elementPhotoInput = document.querySelector('.js-input-field')

    const elementWithOfficial = this.withOfficialTarget
    const elementOfficialInput = this.officialInputTarget
    const elementNameInput = this.nameInputTarget

    this.elementTargets.forEach(element => {
      element.classList.add('hidden')
      if (event.target.dataset.go === element.dataset.key) {
        element.classList.remove('hidden')
      }
    })

    if (event.target.dataset.go === 'manual') {
      elementWithOfficial.value = false
      elementOfficialInput.value = null
      elementOfficialInput.nextSibling.innerHTML = ''
    } else if (event.target.dataset.go === 'official') {
      elementWithOfficial.value = true
      elementNameInput.value = null
      elementPhotoInput.value = null
      elementPhoto.innerHTML = 'Elija un archivo desde su dispositivo'
    }
  }
}
