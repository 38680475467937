import { Controller } from 'stimulus'
import Rails from 'rails-ujs'
import Toastr from 'toastr'
import { createElementFromHTML } from '../components/helpers'

export default class extends Controller {
  static targets = ['element']

  submit(event) {
    const form = event.target.closest('form')
    form.addEventListener('ajax:error', evt => {
      const data = evt.detail[0]
      const { notification } = data
      Toastr.error(notification)
    })
    form.addEventListener('ajax:success', evt => {
      const data = evt.detail[0]
      const { tile, id, notification } = data
      const feedbackTile = document.getElementById(`feedback-${id}`)
      feedbackTile.parentNode.replaceChild(createElementFromHTML(tile), feedbackTile)
      Toastr.success(notification)
    })
    Rails.fire(form, 'submit')
  }
}
