import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {}

  async handleInstitutionChange(event) {
    const institution_id = event.target.value
    const url = `/admin2/instituciones/search_slug_institution?institution_id=${institution_id}`
    const input_users_oficio_slug = document.querySelector('.js-input-users-oficio-slug')
    const res = await fetch(url)
    const { slug } = await res.json()
    if (slug) input_users_oficio_slug.value = slug
    else input_users_oficio_slug.value = ''
  }
}
