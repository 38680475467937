import React from 'react'
import { render } from 'react-dom'
import { ContentState, convertToRaw, convertFromHTML } from 'draft-js'
import pullAllBy from 'lodash/pullAllBy'
import HtmlDraftEditor from './editor'

function recoverRelativeToAbsoluteUrls(html) {
  const base = 'https://www.gob.pe'
  return html.replace(/href="((?!http).+)"/, 'href="' + base + '$1"')
}

export const mountEditor = editorHtmlElement => {
  const { editor, simplebox, requirementbox, onlylinkbox, showjump, tagorigin, blacklist, maxlength, placeholder } =
    editorHtmlElement.dataset

  let targetElement

  if (editor) {
    targetElement = document.getElementById(editor)
  } else {
    editorHtmlElement.insertAdjacentHTML('afterend', '<div class="html_editor_wrapper"></div>')
    targetElement = editorHtmlElement.nextElementSibling
  }

  render(
    <HtmlDraftEditor
      id={editorHtmlElement.id}
      element={editorHtmlElement}
      tagorigin={tagorigin}
      isSimpleBox={simplebox === 'true'}
      isRequirementBox={requirementbox === 'true'}
      onlyLinkBox={onlylinkbox === 'true'}
      showJump={showjump === 'true'}
      blackListControls={blacklist && blacklist.split(',')}
      maxLength={parseInt(maxlength || 0, 10)}
      placeholder={placeholder}
    />,
    targetElement
  )
}

document.addEventListener('turbolinks:load', function () {
  let textJsonParse = ''

  document.querySelectorAll('.html_draft_editor_json').forEach(el => {
    textJsonParse = textJsonParse + el.value
  })

  if (textJsonParse.length === 0) {
    document.querySelectorAll('.html_draft_editor').forEach(editorElement => {
      const markupContent = editorElement.value
      if (markupContent) {
        const getMarkupContent = recoverRelativeToAbsoluteUrls(markupContent)
        const blocksFromHTML = convertFromHTML(getMarkupContent)
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
        const stateContentRaw = convertToRaw(state)
        const stateContentRawLength = stateContentRaw.blocks.length
        let keyRemove = []
        if (stateContentRawLength > 0) {
          for (let i = 0; i < stateContentRawLength; i++) {
            if (stateContentRawLength !== 1) {
              if (!stateContentRaw.blocks[i].text.trim()) {
                keyRemove.push({
                  key: stateContentRaw.blocks[i].key
                })
              }
            }
          }
        }
        pullAllBy(stateContentRaw.blocks, keyRemove, 'key')
        editorElement.nextElementSibling.value = JSON.stringify(stateContentRaw)
      }
    })
  }

  document.querySelectorAll('.html_draft_editor_json').forEach(mountEditor)
})
